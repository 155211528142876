import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

const DOMAIN = 'commissions';

export async function getList(offset = 0, limit = 10) {
  const url = `${BASE_API_URL}/${DOMAIN}?_start=${offset}&_limit=${limit}`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getCommissionStatistics(userId) {
  const url = `${BASE_API_URL}/${DOMAIN}/statistics/${userId}`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getCommissionScheme() {
  const url = `${BASE_API_URL}/${DOMAIN}/scheme`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getCommissionDetail(id) {
  const url = `${BASE_API_URL}/${DOMAIN}/${id}`;
  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
