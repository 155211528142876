import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getBalance } from 'services/balance';
import lodash from 'lodash';
import { showError } from 'utils/toastMessage';
// import { SET_ERROR } from 'redux/error/actions';
import { FETCH_BALANCE } from './actions';

export function* REQUEST_BALANCE() {
  yield put({
    type: FETCH_BALANCE.PENDING,
  });
  try {
    const response = yield call(getBalance);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_BALANCE.ERROR,
      });
    } else {
      yield put({
        type: FETCH_BALANCE.SUCCESS,
        payload: {
          total_in_amount: response.data && response.data.data.total_in_amount,
          total_out_amount: response.data && response.data.data.total_out_amount,
        },
      });
    }
  } catch (err) {
    yield put({
      type: FETCH_BALANCE.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FETCH_BALANCE.REQUEST, REQUEST_BALANCE)]);
}
