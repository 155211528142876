import React, { useState, useEffect } from 'react';
import { Modal, Input, Form } from 'antd';
import { useWeb3React } from '@web3-react/core';
import history from 'routerHistory';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import lodash from 'lodash';
import BigNumber from 'bignumber.js';
import { ethers, BigNumber as EtherBigNumber } from 'ethers';
import { showError, showSuccess, showInfo } from 'utils/toastMessage';
import ProgramItem from 'components/ProgramItem';
import { bscMainnet } from 'constants/networkInfo';
import BHOContractABI from 'constants/contract-abi.json';
import { BASE_URL, BSC_SCAN_URL } from 'configs';
import { injectedconnector } from 'utils/wallet/connectors';
import { generateMetamaskLink, generateTrustLink } from 'utils/wallet/deeplinkGenerator';
import PaymentOption from './PaymentOption';
import ConfirmView from './ConfirmView';
import styles from './style.module.scss';

const ProgramView = (props) => {
  const { program, walletAddress, roundSetting, back } = props;
  const { wallet, apy } = program;
  const [depositAmount, setDepositAmount] = useState('');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [loading, setLoading] = useState(false);
  const [isConfirmView, setIsConfirmView] = useState(false);
  const [walletClicked, setWalletClicked] = useState('');
  const [focus, setFocus] = useState(false);
  const { account, activate, library, error, chainId } = useWeb3React();
  const intl = useIntl();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    activate(injectedconnector);
  }, [activate]);
  useEffect(() => {
    if (depositAmount) {
      setRewardAmount((depositAmount * apy * 90.0) / (365 * 100));
    } else {
      setRewardAmount('0');
    }
  }, [depositAmount, setRewardAmount, apy]);

  useEffect(() => {
    if (roundSetting.sale_start_time) {
      const current = dayjs();
      if (
        current.diff(dayjs(roundSetting.sale_start_time)) >= 0 &&
        current.diff(dayjs(roundSetting.end_sale_time)) <= 0 &&
        Number.parseFloat(depositAmount) >= Number.parseFloat(lodash.get(program, 'min_limit'))
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [depositAmount, program, roundSetting]);

  const showConfirmPayment = (link) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'create_staking.make_payment' }),
      content: (
        <div>
          <p>{intl.formatMessage({ id: 'create_staking.make_payment_content' })}</p>
          <ul>
            <li>
              <a className='link' href={`${BSC_SCAN_URL}/address/${walletAddress}`} target='_blank' rel='noreferrer'>
                {walletAddress}
              </a>
            </li>
          </ul>
        </div>
      ),
      onOk() {
        window.open(link, '_blank');
        showSuccess('createStakingSuccessfully');
        back();
      },
      onCancel() {},
    });
  };

  const sendMetamaskPayment = async () => {
    if (isMobile) {
      const link = generateMetamaskLink(bscMainnet.chainID, bscMainnet.BHO.contractAddress, depositAmount, 18, wallet);
      showConfirmPayment(link);
    } else {
      setLoading(true);
      const signer = library.getSigner();
      const contract = new ethers.Contract(
        bscMainnet.BHO.contractAddress,
        BHOContractABI.contracts.CoinBHO.abi,
        signer
      );
      const amountBigNumber = BigNumber(depositAmount).multipliedBy(1000000000000000000);

      const transferContract = await contract.populateTransaction.transfer(
        wallet,
        EtherBigNumber.from(amountBigNumber.toFixed())
      );

      try {
        if (chainId !== bscMainnet.chainId) {
          try {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: bscMainnet.hexChainId }],
            });
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (error.code === 4902) {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: bscMainnet.hexChainId, // A 0x-prefixed hexadecimal string
                    chainName: bscMainnet.name,
                    nativeCurrency: {
                      name: 'BNB',
                      symbol: bscMainnet.symbol, // 2-6 characters long
                      decimals: 18,
                    },
                    rpcUrls: [bscMainnet.rpcUrl],
                    blockExplorerUrls: [bscMainnet.blockExplorerUrl],
                  },
                ],
              });
            }
            // handle other "switch" errors
          }
        }
        const transactionParameters = {
          nonce: '0x00', // ignored by MetaMask
          from: account, // must match user's active address.
          to: bscMainnet.BHO.contractAddress,
          // value: "0x38",
          data: transferContract.data,
          chainId: bscMainnet.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask. 0x38 for bsc
        };

        const txHash = await library.provider.request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        });

        if (txHash) {
          showInfo('createStakingSuccessfully');
          back();
        }
      } catch (err) {
        if (err.code === 4001) {
          showError('rejectPayment');
        }
      }
      setLoading(false);
    }
  };

  const sendWalletConnectPayment = async () => {
    if (isMobile) {
      const link = generateTrustLink('BHO', wallet, depositAmount);
      showConfirmPayment(link);
    } else {
      setLoading(true);
      const signer = library.getSigner();
      const tokenAddr = bscMainnet.BHO.contractAddress;
      const contract = new ethers.Contract(tokenAddr, BHOContractABI.contracts.CoinBHO.abi, signer);
      const amountBigNumber = BigNumber(depositAmount).multipliedBy(1000000000000000000);

      const transferContract = await contract.populateTransaction.transfer(
        wallet,
        EtherBigNumber.from(amountBigNumber.toFixed())
      );

      // const value = sanitizeHex(
      //   convertStringToHex(`${depositAmount}00000000000000`)
      // );

      try {
        const transactionParameters = {
          from: account, // must match user's active address.
          to: tokenAddr,
          data: transferContract.data,
        };

        const txHash = await library.provider.request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        });

        if (txHash) {
          showInfo('createStakingSuccessfully');
          history.push(`${BASE_URL}/staking/program-list`);
        }
        library.provider.disconnect();
      } catch (err) {
        library.provider.disconnect();
        if (err.code === 4001) {
          showError('rejectPayment');
        }
      }
      setLoading(false);
    }
  };

  const onFinishChooseWallet = (chosenWallet) => {
    setIsConfirmView(true);
    setWalletClicked(chosenWallet);
  };

  if (isConfirmView) {
    return (
      <ConfirmView
        program={program}
        cancel={() => setIsConfirmView(false)}
        walletAddress={walletAddress}
        walletClicked={walletClicked}
        backToProgramView={back}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <ProgramItem program={program} />
      <div className={styles.action_wrapper}>
        <div className={styles.action_title}>
          <FormattedMessage id='create_staking.deposit' />
        </div>

        <div className={styles.input_wrapper}>
          <div className={styles.input_label}>
            <FormattedMessage id='create_staking.amount' />
          </div>
          <div className={styles.input_box}>
            <Form style={{ width: '90%' }} validateTrigger={['onChange', 'onBlur', 'onFocus']}>
              <Form.Item
                className={styles.number_input}
                name='amount'
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value ||
                        Number.parseFloat(value) >= Number.parseFloat(lodash.get(program, 'min_limit')) ||
                        !focus
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `${intl.formatMessage({
                            id: 'create_staking.cannot_stake_less_than',
                          })} ${lodash.get(program, 'min_limit')} ${intl.formatMessage({
                            id: 'create_staking.bho_a_time',
                          })}`
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type='number'
                  onChange={(e) => setDepositAmount(e.target.value)}
                  onBlur={() => setFocus(false)}
                  onFocus={() => setFocus(true)}
                />
              </Form.Item>
            </Form>
            <div
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              BHO
            </div>
          </div>
        </div>

        <div className={styles.button_group}>
          <PaymentOption
            next={onFinishChooseWallet}
            sendMetamaskPayment={sendMetamaskPayment}
            loading={loading}
            sendWalletConnectPayment={sendWalletConnectPayment}
            isDisabled={disableButton}
          />
        </div>
      </div>
      <div className={styles.reward_wrapper}>
        <div className={styles.label_badge}>
          <FormattedMessage id='create_staking.rewards' />
        </div>
        <div className={styles.reward}>
          <div>
            <FormattedMessage id='create_staking.total_reward' />
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
            }}
          >{`${rewardAmount} BHO`}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgramView;
