import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import ErrorLayout from 'layouts/NetworkError';
import { SET_ERROR } from 'redux/error/actions';
import { FormattedMessage } from 'react-intl';
import Image from 'components/Image';
import styles from './style.module.scss';

const ErrorBoundary = (props) => {
  const { user, error, dispatch, children } = props;
  if (error.error) {
    return (
      <ErrorLayout user={user}>
        <div className={styles.maintenance}>
          <div className={styles.centerize}>
            <div className={styles.content}>
              <FormattedMessage id='network.error' />
              <div>
                <Button
                  className={styles.button}
                  onClick={() => {
                    dispatch({
                      type: SET_ERROR,
                      payload: {
                        error: '',
                      },
                    });
                  }}
                >
                  Try again!
                </Button>
              </div>
            </div>
            <Image src='/images/astronaut-error.png' alt='astronaut' className={styles.astronaut} />
          </div>
        </div>
      </ErrorLayout>
    );
  }

  return children;
};

const mapStateToProps = (state) => ({
  error: state.error,
  user: state.user,
});

export default connect(mapStateToProps)(ErrorBoundary);
