import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

const Container = (props) => {
  const { className, children } = props;
  return <div className={classNames([styles.my_container, className])}>{children}</div>;
};

export default Container;
