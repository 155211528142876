export const DASHBOARD_TAB_NAV_ID = 'DASHBOARD_TAB_NAV_ID';
export const REFERRAL_TAB_NAV_ID = 'REFERRAL_TAB_NAV_ID';
export const STAKING_TAB_NAV_ID = 'STAKING_TAB_NAV_ID';
const routesList = [
  {
    id: DASHBOARD_TAB_NAV_ID,
    url: '/dashboard',
    label: 'navbar.dashboard',
    icon: '/images/SquaresFour.svg',
  },
  {
    id: REFERRAL_TAB_NAV_ID,
    url: '/referral-manager',
    label: 'navbar.referral',
    icon: '/images/Link.svg',
  },
  {
    id: STAKING_TAB_NAV_ID,
    url: '/staking/program-list',
    label: 'navbar.staking',
    icon: '/images/Staking.svg',
  },
];
export default routesList;
