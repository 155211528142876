import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

const PROGRAM_DOMAIN = 'staking-programs';

const STAKE_DOMAIN = 'stakings';

export async function getStakeList({ offset = 0, limit = 20 }) {
  const url = `${BASE_API_URL}/${STAKE_DOMAIN}?_start=${offset}&_limit=${limit}`;
  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getStakeDetail(id) {
  return requestAPI({
    url: `${BASE_API_URL}/${STAKE_DOMAIN}/${id}`,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getStakeProgramList({ offset = 0, limit = 20, locale = 'en' }) {
  const url = `${BASE_API_URL}/${PROGRAM_DOMAIN}?_locale=${locale}&_start=${offset}&_limit=${limit}`;
  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
