import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import history from 'routerHistory';
import Image from 'components/Image';
import analytics from 'utils/analytics';
import styles from './style.module.scss';
import { BASE_URL } from '../../configs';

const ThankYou = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    setTimeout(() => {
      history.push(`${BASE_URL}/dashboard`);
    }, 1000);

    analytics.sendEvent({
      action: 'thank_you_screen',
      label: 'visit_screen',
    });
  }, []);

  return (
    <div className={styles.thank_you}>
      <Helmet title='Thank you' />
      <div className={styles.block}>
        <div className={styles.inner}>
          <div className={styles.thank_you_image}>
            <Image src='/images/wallet.svg' alt='wallet' />
          </div>
          <h2>
            <FormattedMessage id='thankyou.info_1' />
          </h2>
          <Spin indicator={antIcon} />
          <p>
            <FormattedMessage id='thankyou.info_2' />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
