import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Steps } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import lodash from 'lodash';
import { Helmet } from 'react-helmet';
import { referralSelector } from 'redux/referral/reducers';
import referralAction from 'redux/referral/actions';
import { FormattedMessage } from 'react-intl';
import { userSelector } from 'redux/user/reducers';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
import styles from './style.module.scss';
import Step1 from './step1';
import Step2 from './step2';
import analytics from '../../utils/analytics';

// import CountDownBlock from './CountDownBlock';
// import Step3 from "./step3";
// import ImageForm from "./ImageForm";

const { Step } = Steps;

class Signup extends Component {
  constructor(props) {
    super(props);
    const { location, forceStep, referral, dispatch } = props;

    this.state = {
      step: forceStep || 0,
    };

    const oldReferralCode = lodash.get(referral, 'code');
    const query = qs.parse(location.search.replace('?', ''), {
      referral_code: true,
      referral_type: true,
    });
    this.referralCode = query.referral_code || oldReferralCode;
    if (this.referralCode && this.referralCode !== oldReferralCode) {
      dispatch({
        type: referralAction.SET_REFERAL_STATE,
        payload: {
          code: this.referralCode,
        },
      });
    }
    this.referralType = query.referral_type || 'nom';
    this.steps = [
      {
        title: <FormattedMessage id='signup.signup' />,
        content: (
          <Step1
            // eslint-disable-next-line react/destructuring-assignment
            loading={this.props.loading}
            next={this.next}
            referralCode={this.referralCode}
            referralType={this.referralType}
          />
        ),
      },
      {
        title: <FormattedMessage id='signup.verify' />,
        // eslint-disable-next-line react/destructuring-assignment
        content: <Step2 loading={this.props.loading} next={this.next} />,
      },
    ];
  }

  componentDidMount = () => {
    const {
      // roundSetting,
      // dispatch,
      user,
      history,
    } = this.props;
    analytics.sendEvent({
      action: 'signup_screen',
      label: 'visit_screen',
    });
    if (user.account_status === 'unverified') {
      history.push('/verify-otp');
    }
  };

  componentDidUpdate(prevProps) {
    const {
      // roundSetting,
      user,
      history,
    } = this.props;
    if (user !== prevProps.user && user.account_status === 'unverified') {
      history.push('/verify-otp');
    }
  }

  next = (values) => {
    // console.log("next");
    if (values) {
      this.setState((prevState) => ({
        step: prevState.step + 1,
      }));
      return;
    }
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  countDownComplete = () => {
    window.location.reload();
  };

  render() {
    const { step } = this.state;
    // const { roundSetting } = this.props;
    return (
      <div className={styles.signup}>
        <Helmet title='Signup' />

        <div className={styles.block}>
          <div className={step === 0 ? styles.inner : styles.verify_inner}>
            {step === 0 ? (
              <Link to={`${BASE_URL}/login`} className={styles.back_arrow}>
                <ArrowLeftOutlined style={{ fontSize: '20px' }} />
              </Link>
            ) : null}

            <div className={styles.step}>
              {/* {currentRegistrationStage === 'is_registration' ? ( */}
              <Steps current={step}>
                {this.steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              {/* ) : null} */}
            </div>
            <div className={styles.image_wrapper}>
              <Image src='/images/signup-image.svg' alt='login' />
            </div>
            <div className={styles.form_wrapper}>
              <div className={styles.title}>{this.steps[step].title}</div>
              {/* {step === 0 ? (
                <CountDownBlock
                  currentRegistrationStage={currentRegistrationStage}
                  roundSetting={roundSetting}
                  onComplete={this.countDownComplete}
                />
              ) : null} */}

              {step > -1 && step < 2 ? this.steps[step].content : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // roundSetting: getRoundSetting(state),
  referral: referralSelector(state),
  user: userSelector(state),
});
export default withRouter(connect(mapStateToProps)(Signup));
