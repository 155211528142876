import { DASHBOARD_TAB_NAV_ID } from 'components/LayoutComponents/NavBar/routes';
import { SET_NAV_BAR_ACTIVE_TAB, SET_NAV_BAR_IS_OPEN } from './actions';

const initialState = {
  isOpen: false,
  activeTab: DASHBOARD_TAB_NAV_ID,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAV_BAR_IS_OPEN:
      return { ...state, isOpen: action.payload };
    case SET_NAV_BAR_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
}

export const isNavBarOpenSelector = (state) => state.navbar.isOpen;
export const activeTabNavBarSelector = (state) => state.navbar.activeTab;
