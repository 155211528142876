import { all, call, put, takeEvery } from 'redux-saga/effects';

import lodash from 'lodash';
import { getStakeList, getStakeDetail } from 'services/stake';
import { showError } from 'utils/toastMessage';

import { FETCH_STAKE_LIST, FETCH_STAKE_DETAIL } from './actions';

export function* REQUEST_STAKE_DETAIL({ payload }) {
  yield put({
    type: FETCH_STAKE_DETAIL.PENDING,
  });
  try {
    const { id } = payload;
    const response = yield call(getStakeDetail, id);

    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_STAKE_DETAIL.ERROR,
      });
    } else {
      yield put({
        type: FETCH_STAKE_DETAIL.SUCCESS,
        payload: {
          data: (response.data && response.data.data) || {},
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_STAKE_DETAIL.ERROR,
    });
  }
}

export function* REQUEST_STAKE_LIST({ payload }) {
  yield put({
    type: FETCH_STAKE_LIST.PENDING,
  });
  try {
    const { offset, limit } = payload;

    const response = yield call(getStakeList, { ...payload });
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_STAKE_LIST.ERROR,
      });
    } else {
      yield put({
        type: FETCH_STAKE_LIST.SUCCESS,
        payload: {
          data: (response.data && response.data.data) || [],
          currentOffset: offset + (limit || 20),
          total: response.data && response.data.total,
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_STAKE_LIST.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_STAKE_LIST.REQUEST, REQUEST_STAKE_LIST),
    takeEvery(FETCH_STAKE_DETAIL.REQUEST, REQUEST_STAKE_DETAIL),
  ]);
}
