import axios from 'axios';
import { SET_ERROR } from 'redux/error/actions';
import actions from 'redux/user/actions';
import { showError } from 'utils/toastMessage';
import lodash from 'lodash';
import { store } from '../store';
// import { get } from 'lodash'

// import CONFIG from '../../configs/configs'
// import persistedStore from '../index'

export const GLOBAL_ACTIONS = {
  FETCH: 'FETCH_DATA',
};

/**
 * Fetch data action
 * Using for dispatch action that make a request api and then handle data with flow
 *  options = {
    type:  action type of action that user defined
    requestParams: body data params for the api request
    request: `requestAPI` function with options that user defined
    useReducer: name of the reducer that response data will be used. Default it will use `app` reducer
    stateName: name of the state that response data will by used. MUST BE DEFINE. If not, it will use  `data` state and something may be lost.
}
 *
 * @param {object} options
 */
export const fetchDataAction = ({ type, requestParams = {}, request, useReducer = 'app', stateName = 'data' }) => ({
  type: GLOBAL_ACTIONS.FETCH,
  payload: {
    action: {
      type,
      requestParams,
    },
    request,
    useReducer,
    stateName,
  },
});

/**
 * API request by default using axios
 * Easy way to call api by set url, method and bodyParams
 *
 * options = {
    url = '', // url to call request
    bodyData = null, // data to pass through request body if POST method (JSON format)
    headers, // override heares of request
    credentials = 'same-origin', //default is same-origin
    mode = 'same-origin', //default is same-origin
    method = 'GET' //if not defined, default will be GET
 }
 *
 * @param {object} options
 */

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // if 401 unauthorized reset reducers
    if (error.response.status === 401) {
      store.dispatch({
        type: actions.RESET,
      });
    }
    // if 403 and check empty jwt_token
    const state = store.getState();
    if (error.response.status === 403 && !lodash.get(state, 'user.jwt_token')) {
      store.dispatch({
        type: actions.RESET,
      });
    }
    if (error.response.status === 429) {
      showError('error.too_many_request');
    }
    if (error.response.status === 503) {
      store.dispatch({
        type: SET_ERROR,
        payload: {
          error: 'Network error',
        },
      });
    }
    return Promise.reject(error);
  }
);

export const requestAPI = ({ url = '', bodyData = null, headers, method = 'GET' }) => {
  // const userToken = store.get('jwt_token');
  const state = store.getState();
  const userToken = lodash.get(state, 'user.jwt_token');
  const requestParams = {
    url,
    method,
    // credentials,
    // mode,
    headers: headers || {
      'Content-Type': 'application/json',

      ...(userToken ? { Authorization: `Bearer ${userToken}` } : undefined),
    },
    ...(bodyData ? { data: JSON.stringify(bodyData) } : { data: '' }),
  };

  return axios(requestParams);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  requestAPI,
  fetchDataAction,
};
