import { build } from 'eth-url-parser';

const BASE_URL = 'https://metamask.app.link';

export const generateMetamaskLink = (chainID, contractAddress, value, decimals, receiverAddress) => {
  try {
    const data = {
      scheme: 'ethereum',
      prefix: null,
      target_address: contractAddress,
      chain_id: chainID,
      function_name: 'transfer',
      parameters: {
        address: receiverAddress,
        uint256: `${value}e${decimals}`,
      },
    };

    let url = build(data);

    url = url.replace('ethereum:', `${BASE_URL}/send/`);
    return url;
  } catch (e) {
    // alert(e.toString());
    return null;
  }
};

export const generateTrustLink = (asset, receiverAddress, value) => {
  const assetCodes = {
    BUSD: 'c20000714_t0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    USDT: 'c20000714_t0x55d398326f99059fF775485246999027B3197955',
    BHO: 'c20000714_t0x8717e80EfF08F53A45b4A925009957E14860A8a8',
  };
  const url = `https://link.trustwallet.com/send?asset=${assetCodes[asset]}&address=${receiverAddress}&amount=${value}`;
  return url;
};
