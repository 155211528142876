import React from 'react';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { GlobalOutlined } from '@ant-design/icons';
import actions from 'redux/user/actions';
import Image from 'components/Image';
import { getAllPaymentMethodList } from 'redux/payment-method/reducers';
import styles from './style.module.scss';

class LanguageSelector extends React.Component {
  changeLang = ({ key }) => {
    const { dispatch, user, allPaymentMethodList } = this.props;
    if (user.account_status === 'active') {
      const isSavedPaymentLocaleMatched = allPaymentMethodList.some((method) => method.locale === key);
      const payloadData = {
        data: {
          locale: key,
          isSavedPaymentLocaleMatched,
        },
      };
      if (isSavedPaymentLocaleMatched) {
        payloadData.data.existMatchedPaymentMethodList = allPaymentMethodList.filter((method) => method.locale === key);
      }
      dispatch({
        type: actions.UPDATE_PROFILE,
        payload: payloadData,
      });
    } else {
      dispatch({
        type: actions.SET_STATE,
        payload: {
          locale: key,
        },
      });
    }
  };

  render() {
    const {
      user: { locale },
    } = this.props;

    const langMenu = (
      <Menu
        className={styles.menu}
        selectedKeys={[locale]}
        onClick={this.changeLang}
        style={{
          backgroundColor: '#1FC7D4',
          color: 'white',
          marginTop: '5px',
        }}
      >
        <Menu.Item key='en' style={{ color: 'white' }}>
          <span role='img' aria-label='English' className='mr-2'>
            <Image className={styles.flag_icon} src='/images/gb-flag.svg' alt='gb-flag' />
            &nbsp;
          </span>
          English
        </Menu.Item>
        <Menu.Item key='vi' style={{ color: 'white' }}>
          <span role='img' aria-label='Tiếng Việt' className='mr-2'>
            <Image className={styles.flag_icon} src='/images/vn-flag.svg' alt='vn-flag' />
            &nbsp;
          </span>
          Tiếng Việt
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <GlobalOutlined style={{ color: 'white' }} />

          <strong style={{ color: 'white' }}>
            &nbsp;
            {locale || 'en'}
          </strong>
        </div>
      </Dropdown>
    );
  }
}

export default connect((state) => ({ allPaymentMethodList: getAllPaymentMethodList(state) }), null)(LanguageSelector);
