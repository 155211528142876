import { combineReducers } from 'redux';
import { FETCH_STAKING_ROUND_SETTING } from './actions';

const initialState = {
  isFetching: false,
  sale_start_time: '',
  sale_end_time: '',
  registration_start_time: '',
  registration_end_time: '',
  next: {
    sale_start_time: '',
    sale_end_time: '',
    registration_start_time: '',
    registration_end_time: '',
  },
};

const fetchRoundSetting = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAKING_ROUND_SETTING.PENDING:
      return { ...state, isFetching: true };
    case FETCH_STAKING_ROUND_SETTING.SUCCESS:
      return {
        isFetching: false,
        ...action.payload,
      };
    case FETCH_STAKING_ROUND_SETTING.ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({
  fetchRoundSetting,
});
// Selector here
export const getRoundSetting = (state) => state.stakingConfig.fetchRoundSetting;
