import React from 'react';
import styles from './style.module.scss';

const Progress = (props) => {
  const { width, percent, backgroundColor, color, height } = props;
  return (
    <div className={styles.wrapper}>
      <div style={{ width, backgroundColor, height: height || 10 }}>
        <div
          style={{
            width: `${percent}%`,
            backgroundColor: color,
            height: height || 10,
          }}
        />
      </div>
      <div className={styles.label}>{`${percent} %`}</div>
    </div>
  );
};

export default Progress;
