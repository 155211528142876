import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions from 'redux/user/actions';
import analytics from 'utils/analytics';
import { RecaptchaComponent } from 'components/Recaptcha';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
import styles from './style.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const forceStep = location && location.state && location.state.step;
    this.state = {
      step: forceStep || 1,
    };
  }

  componentDidMount = () => {
    analytics.sendEvent({
      action: 'login_screen',
      label: 'visit_screen',
    });
  };

  onFinish = async (values) => {
    const { dispatch, googleReCaptchaProps } = this.props;
    const { executeRecaptcha } = googleReCaptchaProps;
    try {
      const token = await executeRecaptcha('SignIn');
      dispatch({
        type: actions.LOGIN,
        payload: {
          captchaToken: token,
          ...values,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      user: { loading },
    } = this.props;
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className={styles.login}>
          <Helmet title='Login' />
          <div className={styles.bottom_section}>
            <div className={styles.centerize}>
              <div>
                <Button
                  className={[styles.auth_button, styles.login_button]}
                  onClick={() => this.setState({ step: 2 })}
                >
                  <FormattedMessage id='login.login' />
                </Button>
              </div>
              <Link to={`${BASE_URL}/signup`}>
                <Button className={[styles.auth_button, styles.signup_button]}>
                  <FormattedMessage id='login.create_new_account' />
                </Button>
              </Link>
              <Link to={`${BASE_URL}/forgot-password`}>
                <div className={styles.forgot_password}>
                  <FormattedMessage id='login.forgot_password?' />
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.login}>
        <Helmet title='Login' />

        <div className={styles.block}>
          <div className={styles.inner}>
            <Button className={styles.back_arrow} type='link' onClick={() => this.setState({ step: 1 })}>
              <ArrowLeftOutlined style={{ fontSize: '20px' }} />
            </Button>
            <div className={styles.image_wrapper}>
              <Image src='/images/login-image.svg' alt='login' />
            </div>
            <div className={styles.form_wrapper}>
              <div className={styles.title}>
                <FormattedMessage id='login.title' />
              </div>
              <div className={styles.form}>
                <Form layout='vertical' hideRequiredMark onFinish={this.onFinish}>
                  <Form.Item
                    label={<FormattedMessage id='login.email' />}
                    name='username'
                    rules={[
                      {
                        type: 'email',
                        message: <FormattedMessage id='login.email_invalid' />,
                      },
                      {
                        required: true,
                        message: <FormattedMessage id='login.email_required' />,
                      },
                    ]}
                  >
                    <Input size='large' className={styles.login_input} type='email' />
                  </Form.Item>
                  <Form.Item
                    label={<FormattedMessage id='login.password' />}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id='login.password_required' />,
                      },
                    ]}
                  >
                    <Input.Password
                      size='large'
                      iconRender={(visible) =>
                        visible ? (
                          <EyeOutlined style={{ color: '#fff' }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ color: '#fff' }} />
                        )
                      }
                      className={styles.login_input}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button className={[styles.auth_button, styles.login_button]} htmlType='submit' loading={loading}>
                      <FormattedMessage id='login.login' />
                    </Button>
                  </Form.Item>

                  <Link to={`${BASE_URL}/forgot-password`}>
                    <div className={styles.forgot_password}>
                      <FormattedMessage id='login.forgot_password?' />
                    </div>
                  </Link>

                  <div className={styles.dont_have_account_text}>
                    <FormattedMessage id='login.dont_have_an_account' />{' '}
                    <Link
                      className={styles.signup_now_text}
                      to={{
                        pathname: '/signup',
                      }}
                    >
                      <FormattedMessage id='login.sign_up_now' />
                    </Link>
                    <br />
                    <FormattedMessage id='login.explore_staking_event' />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(RecaptchaComponent(withGoogleReCaptcha(connect(mapStateToProps)(Login))));
