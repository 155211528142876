/*
 * Convert an array into object literal collections with predefined property keys
 * */
export const convertToCollections = (items, idProp) =>
  items.reduce((coll, currentItem) => ({ ...coll, [currentItem[idProp]]: { ...currentItem } }), {});

/*
 * Append into byId, update existing object
 * */
export const appendByIdsUpdateOld = (byId, items, idProp) => ({ ...byId, ...convertToCollections(items, idProp) });

/*
 * Append into byId, not update existing object
 * */
export const appendByIds = (byId, items, idProp) => ({ ...convertToCollections(items, idProp), ...byId });

/*
 * Append new items into an id array without duplicated items
 * */
export const appendIdArrayWithoutDuplication = (arr, items, idProp) => {
  const arrSet = new Set(arr);
  items.forEach((item) => arrSet.add(item[idProp]));
  return Array.from(arrSet);
};

/**
 * Delete key-value pair from byId
 */
export const deleteKeyValueFromById = (byId, key) => {
  const newById = { ...byId };
  delete newById[key];
  return newById;
};

export const updateById = (byId, item, idProp) => ({
  ...byId,
  [item[idProp]]: {
    ...item,
  },
});

export const addKeyToObjects = (total, array, keyProp, useIndex, reverse = false) => {
  if (useIndex) {
    return array.map((item, index) => ({
      ...item,
      key: reverse ? total - index : index,
    }));
  }
  return array.map((item) => ({
    ...item,
    key: item[keyProp],
  }));
};

export const mapObject2Array = (obj, keyName) => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map((key) => ({
    [keyName]: key,
    value: obj[key],
  }));
};
