import React, { useState, Fragment, useEffect } from 'react';
import { Button } from 'antd';
import { useWeb3React } from '@web3-react/core';
// import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import {
  // walletconnect,
  injectedconnector,
} from 'utils/wallet/connectors';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import lodash from 'lodash';
import { processWallet } from 'services/user';
import { showError } from 'utils/toastMessage';
// import { SET_ERROR } from 'redux/error/actions';
import Image from 'components/Image';
import styles from './style.module.scss';

const PaymentOption = (props) => {
  // const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const context = useWeb3React();
  const {
    connector,
    account,
    activate,
    //  deactivate
  } = context;
  const { sendMetamaskPayment, loading, sendWalletConnectPayment, isDisabled, next } = props;

  useEffect(() => {
    if (connector && account) {
      setIsConnected(true);
    }
  }, [connector, account]);

  useEffect(() => {
    if (account && isConnected && isSending) {
      if (connector instanceof InjectedConnector) preSendPayment('metamask');
      if (connector instanceof WalletConnectConnector) preSendPayment('walletconnect');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isSending]);

  // const connectWalletConnect = async () => {
  //   if (connector && lodash.get(connector, 'walletConnectProvider.wc.uri')) {
  //     connector.walletConnectProvider = undefined;
  //   }
  //   await deactivate(walletconnect);
  //   await activate(walletconnect);
  //   setIsSending(true);
  // };

  const connectMetamask = async () => {
    await activate(injectedconnector);
    setIsSending(true);
  };

  const preSendPayment = async (connectType) => {
    setIsSending(false);
    try {
      const res = await processWallet(account);
      if (res.data.error) {
        showError(lodash.get(res, 'data.message.messages[0].id'));
      } else {
        const { valid } = res.data;
        if (valid) {
          connectType === 'metamask' ? sendMetamaskPayment() : sendWalletConnectPayment();
          return;
        }
        showError('Wallet.existed_wallet');
      }
    } catch (err) {
      console.log(err);
      // dispatch({
      //   type: SET_ERROR,
      //   payload: {
      //     error: 'Network error',
      //   },
      // });
    }
  };

  return (
    <>
      <Button
        className={styles.metamask_button}
        onClick={isMobile ? sendMetamaskPayment : connectMetamask}
        loading={loading}
        disabled={isDisabled}
      >
        <Image src='/images/metamask.svg' alt='Metamask' />
      </Button>

      <Button
        className={styles.walletconnect_button}
        onClick={isMobile ? sendWalletConnectPayment : () => next('trust')}
        loading={loading}
        disabled={isDisabled}
      >
        <Image src='/images/trust.svg' alt='TrustWallet' style={{ width: '100%' }} />
      </Button>

      <Button className={styles.manual_button} onClick={() => next('manual')} loading={loading} disabled={isDisabled}>
        <Image src='/images/manualy.svg' alt='Manualy' />
        <FormattedMessage id='create_request.manually' />
      </Button>
    </>
  );
};

export default PaymentOption;
