import React from 'react';
import { Button, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { copyStringToClipboard } from 'utils/clipboard';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

const CopyButton = (props) => {
  const { content, withText } = props;
  const copy = () => {
    copyStringToClipboard(content);
    notification.success({
      message: 'Copied',
    });
  };
  return (
    <Button
      className={[styles.copy_button, typeof withText === 'undefined' ? styles.no_text : '']}
      icon={<CopyOutlined style={{ color: 'white' }} />}
      onClick={copy}
      size='small'
      type='link'
    >
      {withText && (
        <>
          {' '}
          <FormattedMessage id='button.copy' />
        </>
      )}
    </Button>
  );
};

export default CopyButton;
