/* eslint-disable react/react-in-jsx-scope */
import history from 'routerHistory';
import { decode as base64_decode } from 'base-64';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import lodash from 'lodash';
import { BASE_URL } from 'configs';
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import { store as storeRedux } from '../../store';
import actions from '../../redux/user/actions';

const RedirectPage = ({ location }) => {
  const query = qs.parse(location.search.replace('?', ''));
  const destination = `${BASE_URL}/${query.d}`;
  const token = query.t;
  if (!destination || !token) history.push(`${BASE_URL}/404`);

  const state = storeRedux.getState();
  const storedToken = lodash.get(state, 'user.jwt_token');
  const currentToken = base64_decode(token);
  if (currentToken && currentToken !== storedToken) {
    storeRedux.dispatch({
      type: actions.SET_STATE,
      payload: {
        jwt: currentToken,
      },
    });
    storeRedux.dispatch({
      type: actions.LOAD_CURRENT_ACCOUNT,
      payload: {},
    });
    setTimeout(() => {
      history.push(destination);
    }, 200);
  } else if (storedToken) {
    storeRedux.dispatch({
      type: actions.LOAD_CURRENT_ACCOUNT,
      payload: {},
    });
    setTimeout(() => {
      history.push(destination);
    }, 200);
  } else {
    history.push(`${BASE_URL}/login`);
  }
  return (
    <Helmet>
      <meta name='robots' content='nofollow' />
      <meta name='googlebot' content='noindex' />
    </Helmet>
  );
};

export default withRouter(RedirectPage);
