import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getRoundTime } from 'services/sale-config';
import lodash from 'lodash';
import { showError, showInfo } from 'utils/toastMessage';
// import { SET_ERROR } from 'redux/error/actions';
import { getCurrentSaleStage } from 'utils/sale';
import history from 'routerHistory';
import { FETCH_ROUND_SETTING, UPDATE_ROUND_SETTING } from './actions';

export function* UPDATE_ROUND_SETTING_SAGA({ payload }) {
  yield put({
    type: FETCH_ROUND_SETTING.SUCCESS,
    payload: {
      ...payload,
    },
  });
  setTimeout(() => {
    const currentSaleStage = getCurrentSaleStage(payload);
    if (currentSaleStage !== 'is_selling' && history.location.pathname.indexOf('create-request') !== -1) {
      history.push('/dashboard');
    }
    if (currentSaleStage === 'end_sale') showInfo('sale.public_sale_end');
  }, 1000);
}

export function* REQUEST_ROUND_SETTING() {
  yield put({
    type: FETCH_ROUND_SETTING.PENDING,
  });
  try {
    const response = yield call(getRoundTime);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_ROUND_SETTING.ERROR,
      });
    } else {
      yield put({
        type: FETCH_ROUND_SETTING.SUCCESS,
        payload: {
          ...lodash.get(response, 'data.data'),
          savedAt: new Date().toISOString(),
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_ROUND_SETTING.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_ROUND_SETTING.REQUEST, REQUEST_ROUND_SETTING),
    takeEvery(UPDATE_ROUND_SETTING, UPDATE_ROUND_SETTING_SAGA),
  ]);
}
