const actions = {
  SET_STATE: 'SET_STATE',
  LOGIN: 'LOGIN',
  LOAD_CURRENT_ACCOUNT: 'LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REGISTER: 'REGISTER',
  CONFIRM_OTP: 'CONFIRM_OTP',
  SUBMIT_KYC: 'SUBMIT_KYC',
  ADD_WALLET: 'ADD_WALLET',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  RESET: 'RESET',
};

export default actions;
