export const bscTestnet = {
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  chainId: '97', // "0x61"
  hexChainId: '0x61',
  symbol: 'BNB',
  blockExplorerUrl: 'https://testnet.bscscan.com',
  BUSD: {
    contractAddress: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  },
  BHO: {
    contractAddress: '0x0ef4161b17A8943819e35E0B024A517AfE1a9F36',
  },
};

export const bscMainnet = {
  rpcUrl: 'https://bsc-dataseed.binance.org',
  chainId: '56', // "0x38"
  hexChainId: '0x38',
  symbol: 'BNB',
  blockExplorerUrl: 'https://bscscan.com',
  BUSD: {
    contractAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  USDT: {
    contractAddress: '0x55d398326f99059ff775485246999027b3197955',
  },
  BHO: {
    contractAddress: '0x8717e80EfF08F53A45b4A925009957E14860A8a8',
  },
};

export const ethMainnet = {
  rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  chainId: '1', // "0x1"
  hexChainId: '0x1',
  symbol: 'ETH',
  blockExplorerUrl: 'https://etherscan.io',
  BUSD: {
    contractAddress: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  },
};

export const ethRopstennet = {
  rpcUrl: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  chainId: '3', // "0x3"
  hexChainId: '0x3',
  symbol: 'ETH',
  blockExplorerUrl: 'https://ropsten.etherscan.io',
  BUSD: {
    contractAddress: '0x9661b1e30d8b2e686c222dab1db7ef9ebb495ee0',
  },
};

export const ethRinkebyNet = {
  rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  chainId: '4', // "0x4"
  hexChainId: '0x4',
  symbol: 'ETH',
  blockExplorerUrl: 'https://rinkeby.etherscan.io',
  BUSD: {
    // actually tusdt for testing
    contractAddress: '0xD92E713d051C37EbB2561803a3b5FBAbc4962431',
  },
  TUSDT: {
    // actually tusdt for testing
    contractAddress: '0xD92E713d051C37EbB2561803a3b5FBAbc4962431',
  },
};
