/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { BASE_URL } from '../../configs';

const Image = (props) => {
  const { src, ...imgProps } = props;
  return <img src={`${BASE_URL}${src}`} {...imgProps} />;
};

export default Image;
