/* eslint-disable camelcase */
import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

const DOMAIN = 'auth';

const USER_DOMAIN = 'users';

export async function login(username, password, captchaToken) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/login`,
    bodyData: {
      username,
      password,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function currentAccount() {
  return null;
}

export async function logout() {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/logout`,
    bodyData: {},
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function register(
  username,
  password,
  first_name,
  last_name,
  locale,
  referral_code,
  referral_type,
  captchaToken
) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/register`,
    bodyData: {
      username,
      password,
      first_name,
      last_name,
      locale,
      referral_code,
      referral_type,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function confirmOTP(username, confirmation_otp, captchaToken) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/confirm-otp`,
    bodyData: {
      username,
      confirmation_otp,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function resendOTP(username, is_forgot_password = false, captchaToken) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/resend-otp`,
    bodyData: {
      username,
      is_forgot_password,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function getMe() {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/me`,
    method: 'get',
  })
    .then((res) => res)
    .catch((error) => error);
}

export async function submitKYC(kyc, documents) {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/kyc`,
    bodyData: {
      kyc,
      documents,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function changePassword(password, new_password) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/change-password`,
    bodyData: {
      password,
      new_password,
    },
    method: 'put',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function forgotPassword(username, captchaToken) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/forgot-password`,
    bodyData: {
      username,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function resetPassword(username, confirmation_otp, password, captchaToken) {
  return requestAPI({
    url: `${BASE_API_URL}/${DOMAIN}/reset-password`,
    bodyData: {
      username,
      confirmation_otp,
      password,
      captchaToken,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function addWallet(wallet_address) {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/wallet`,
    bodyData: {
      wallet_address,
    },
    method: 'post',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function getWallet() {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/wallet`,
    method: 'get',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function validateWallet(wallet) {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/validateWallet?wallet=${wallet}`,
    method: 'get',
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function processWallet(wallet) {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/processWallet`,
    method: 'post',
    bodyData: {
      wallet_address: wallet,
    },
  })
    .then((res) => res)
    .catch((error) => error.response);
}

export async function updateProfile(profile) {
  return requestAPI({
    url: `${BASE_API_URL}/${USER_DOMAIN}/profile`,
    bodyData: {
      ...profile,
    },
    method: 'put',
  })
    .then((res) => res)
    .catch((error) => error.response);
}
