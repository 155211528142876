import React, { useState, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { userSelector } from 'redux/user/reducers';
import { resendOTP } from 'services/user';
import actions from 'redux/user/actions';
import { showError } from 'utils/toastMessage';
import { RecaptchaComponent } from 'components/Recaptcha';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './style.module.scss';

const SignupStep2 = (props) => {
  const intl = useIntl();
  const [resendTime, setResendTime] = useState(0);
  const [isCallingResend, setIsCallingResend] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  useEffect(() => {
    // exit early when we reach 0
    if (!resendTime) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setResendTime(resendTime - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [resendTime]);
  const [form] = Form.useForm();
  const { next } = props;

  useEffect(() => {
    if (user.account_status === 'active') {
      next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onFinish = async (values) => {
    const { googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    const captchaToken = await executeRecaptcha('veriftyOTP');
    dispatch({
      type: actions.CONFIRM_OTP,
      payload: {
        ...values,
        username: user.username,
        captchaToken,
      },
    });
  };

  const resendCode = async () => {
    setIsCallingResend(true);
    const { googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    const captchaToken = await executeRecaptcha('resendOTP');
    try {
      const res = await resendOTP(user.username, false, captchaToken);
      if (res.data.data) {
        setResendTime(120);
        setIsCallingResend(false);
      } else {
        setIsCallingResend(false);
        showError(get(res, 'data.message.messages[0].id'));
      }
    } catch (err) {
      setIsCallingResend(false);
      showError(
        intl.formatMessage({
          id: 'signup.cannot_resend_otp',
        })
      );
    }
  };

  return (
    <div className={styles.form}>
      <Form form={form} layout='vertical' hideRequiredMark onFinish={onFinish} className={styles.verify_form_field}>
        <h4>
          <FormattedMessage id='signup.verify_title' />
        </h4>
        <Form.Item
          label={<FormattedMessage id='signup.verify_with_code' />}
          name='confirmation_otp'
          className={styles.verify_field}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='signup.otp_required' />,
            },
          ]}
        >
          <Input size='large' className={styles.sign_up_input} placeholder='Type here...' />
        </Form.Item>
        <div style={{ width: '70%' }}>
          <Form.Item>
            <Button className={styles.sign_up_button} htmlType='submit' loading={user.loading}>
              <FormattedMessage id='signup.next' />
            </Button>
          </Form.Item>
          {resendTime !== 0 && (
            <div className={styles.resend_code}>
              (<FormattedMessage id='signup.resend_in' />
              {` ${resendTime})`}
            </div>
          )}
          {resendTime === 0 && (
            <div className={styles.resend_code}>
              <FormattedMessage id='signup.dont_receive' />
              <Button onClick={resendCode} className={styles.resend_button} type='link' loading={isCallingResend}>
                <FormattedMessage id='signup.resend_code' />
              </Button>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default RecaptchaComponent(withGoogleReCaptcha(SignupStep2));
