import { all } from 'redux-saga/effects';
import user from './user/sagas';
import transaction from './transaction/sagas';
import paymentMethod from './payment-method/sagas';
import commission from './commission/sagas';
import balance from './balance/sagas';
import saleConfig from './sale-config/sagas';
import stakingConfig from './staking-round-config/sagas';
// import watchOnSaleStatistics from './sale-statistics/sagas';
import stakeProgram from './stake-program/sagas';
import stake from './stake/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    transaction(),
    paymentMethod(),
    commission(),
    balance(),
    saleConfig(),
    stakingConfig(),
    // watchOnSaleStatistics(),
    stakeProgram(),
    stake(),
  ]);
}
