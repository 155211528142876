import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

export async function getRoundTime() {
  const url = `${BASE_API_URL}/round-settings/current`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getStakingRoundTime() {
  const url = `${BASE_API_URL}/round-settings/staking`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
