import { SET_ERROR } from './actions';

const initialState = {
  error: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return { ...action.payload };
    default:
      return state;
  }
}

export const errorSelector = (state) => state.error;
