import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getStakingRoundTime } from 'services/sale-config';
import lodash from 'lodash';
import { showError } from 'utils/toastMessage';
import { FETCH_STAKING_ROUND_SETTING } from './actions';

export function* REQUEST_ROUND_SETTING() {
  yield put({
    type: FETCH_STAKING_ROUND_SETTING.PENDING,
  });
  try {
    const response = yield call(getStakingRoundTime);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_STAKING_ROUND_SETTING.ERROR,
      });
    } else {
      yield put({
        type: FETCH_STAKING_ROUND_SETTING.SUCCESS,
        payload: {
          ...lodash.get(response, 'data.data'),
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_STAKING_ROUND_SETTING.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FETCH_STAKING_ROUND_SETTING.REQUEST, REQUEST_ROUND_SETTING)]);
}
