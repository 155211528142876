import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';
import LoginPage from 'pages/login-page';
import SignupPage from 'pages/signup-page';
import DashboardPage from 'pages/dashboard-page';
// import CreateRequestPage from 'pages/create-request-page';
import RequestDetailPage from 'pages/request-detail-page';
import ForgotPasswordPage from 'pages/forgot-pass-page';
import ChangePasswordPage from 'pages/change-pass-page';
import WalletPage from 'pages/wallet-page';
// import ReferralPage from 'pages/referral-page';
import RedirectPage from 'pages/redirect';
import ThankYouPage from 'pages/thank-you';
import StakingPage from 'pages/staking-page';
import StakingDetailPage from 'pages/staking-detail-page';
import history from 'routerHistory';
import { BASE_URL } from './configs';

// const loadable = (loader) =>
//   Loadable({
//     loader,
//     delay: false,
//     loading: () => <Loader />,
//   });

const routes = [
  // System Pages
  {
    path: `${BASE_URL}/dashboard`,
    component: DashboardPage,
    // NOTE: isPrivate mean isUserAuthorized && isVerified && isConnectWallet
    isPrivate: true,
    exact: true,
  },
  // {
  //   path: `${BASE_URL}/create-request`,
  //   component: CreateRequestPage,
  //   isPrivate: true,
  //   exact: true,
  // },
  {
    path: `${BASE_URL}/request-detail/:id`,
    component: RequestDetailPage,
    isPrivate: true,
    exact: true,
  },
  {
    path: `${BASE_URL}/signup`,
    component: SignupPage,
    exact: true,
  },
  {
    path: `${BASE_URL}/verify-otp`,
    component: () => <SignupPage forceStep={1} />,
    noNeedVerified: true,
    isPrivate: true,
    exact: true,
  },
  {
    path: `${BASE_URL}/login`,
    component: LoginPage,
    exact: true,
  },
  {
    path: `${BASE_URL}/forgot-password`,
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    path: `${BASE_URL}/change-password`,
    component: ChangePasswordPage,
    isPrivate: true,
    noNeedWallet: true,
    exact: true,
  },
  {
    path: `${BASE_URL}/wallet`,
    component: WalletPage,
    isPrivate: true,
    noNeedWallet: true,
    exact: true,
  },
  // {
  //   path: `${BASE_URL}/referral-manager`,
  //   component: ReferralPage,
  //   isPrivate: true,
  //   exact: true,
  // },
  {
    path: `${BASE_URL}/redirect`,
    component: RedirectPage,
    exact: true,
  },
  {
    path: `${BASE_URL}/thank-you`,
    component: ThankYouPage,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/staking',
    component: () => <Redirect to='/staking/program-list' />,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/staking/program-list',
    component: () => <StakingPage activeTab='1' />,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/staking/staking-list',
    component: () => <StakingPage activeTab='2' />,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/staking/:id',
    component: StakingDetailPage,
    isPrivate: true,
    exact: true,
  },
];

const CustomRoute = (props) => {
  const { path, component, key, exact, isPrivate, noNeedVerified, noNeedWallet } = props;
  const isUserAuthorized = useSelector((state) => state.user.authorized);
  const isVerified = useSelector((state) => state.user.account_status === 'active');
  const isConnectWallet = useSelector((state) => state.user.wallet_addresses && state.user.wallet_addresses.length > 0);

  const isLoginPage = /^\/login(?=\/|$)/i.test(path);
  const isSignupPage = /^\/signup(?=\/|$)/i.test(path);
  const isVerifyPage = /^\/verify-otp(?=\/|$)/i.test(path);
  const isForgotPage = /^\/forgot-password(?=\/|$)/i.test(path);
  // NOTE: improve this to an attribute like authPage in routeConfigs
  const isPageUseToGetAuthorize = isLoginPage || isSignupPage || isVerifyPage || isForgotPage;

  const RouteComponent = <Route path={path} component={component} key={key} exact={exact} />;

  if (isUserAuthorized === null) {
    // NOTE: waiting for auth state from API, should be return loading
    return null;
  }

  if (isUserAuthorized) {
    if (isVerified) {
      if (isConnectWallet) {
        if (isPageUseToGetAuthorize) {
          return <Redirect to={`${BASE_URL}/staking`} />;
        }

        return RouteComponent;
      }

      if (noNeedWallet) {
        return RouteComponent;
      }

      return <Redirect to={`${BASE_URL}/wallet`} />;
    }

    if (noNeedVerified) {
      return RouteComponent;
    }

    return <Redirect to={`${BASE_URL}/verify-otp`} />;
  }

  if (!isPrivate) {
    return RouteComponent;
  }

  return <Redirect to={`${BASE_URL}/login`} />;
};

const BholdusRouter = () => (
  <Router history={history} basename={BASE_URL}>
    <IndexLayout>
      <Switch>
        <Route exact path={`${BASE_URL}/`} render={() => <Redirect to={`${BASE_URL}/staking`} />} />
        {routes.map((route) => (
          <CustomRoute
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
            isPrivate={route.isPrivate}
            noNeedVerified={route.noNeedVerified}
            noNeedWallet={route.noNeedWallet}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </IndexLayout>
  </Router>
);

export default BholdusRouter;
