import React, { useState, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { resendOTP, resetPassword } from 'services/user';
import { showError } from 'utils/toastMessage';
import { analysePassword } from 'utils/analysePassword';
// import { SET_ERROR } from 'redux/error/actions';
import { BASE_URL } from 'configs';
import { RecaptchaComponent } from 'components/Recaptcha';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './style.module.scss';

const SignupStep2 = (props) => {
  const history = useHistory();
  const intl = useIntl();
  // const dispatch = useDispatch();
  const [resendTime, setResendTime] = useState(30);
  const [loading, setLoading] = useState(false);
  const [isCallingResend, setIsCallingResend] = useState(false);
  useEffect(() => {
    // exit early when we reach 0
    if (!resendTime) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setResendTime(resendTime - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [resendTime]);
  const { username } = props;

  const onFinish = async (values) => {
    const { googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    const captchaToken = await executeRecaptcha('ForgetPassword');
    try {
      setLoading(true);
      const res = await resetPassword(username, values.confirmation_otp, values.password, captchaToken);
      if (!res.data.error) {
        setLoading(false);
        history.push(`${BASE_URL}/login`);
      } else {
        setLoading(false);
        showError(get(res, 'data.message.messages[0].id'));
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      // dispatch({
      //   type: SET_ERROR,
      //   payload: {
      //     error: 'Network error',
      //   },
      // });
    }
  };

  const resendCode = async () => {
    setIsCallingResend(true);
    const { googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    const captchaToken = await executeRecaptcha('resendOTP');
    try {
      const res = await resendOTP(username, true, captchaToken);
      if (res.data.data) {
        setResendTime(30);
        setIsCallingResend(false);
      } else {
        setIsCallingResend(false);
        showError(get(res, 'data.message.messages[0].id'));
      }
    } catch (err) {
      setIsCallingResend(false);
      showError(
        intl.formatMessage({
          id: 'forgot_password.cannot_resend_otp',
        })
      );
    }
  };

  return (
    <div className={styles.form}>
      <Form layout='vertical' hideRequiredMark onFinish={onFinish}>
        <Form.Item
          label={<FormattedMessage id='forgot_password.password' />}
          name='password'
          rules={[
            {
              required: true,
              message: <FormattedMessage id='forgot_password.password_required' />,
            },
            () => ({
              validator(_, value) {
                if (value && !analysePassword(value)) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({
                        id: 'forgot_password.strong_password_required',
                      })
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            size='large'
            className={styles.mail_input}
            placeholder='Type here...'
            iconRender={(visible) =>
              visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
            }
          />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='forgot_password.confirm_password' />}
          name='confirm_password'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='forgot_password.confirm_password_required' />,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    intl.formatMessage({
                      id: 'forgot_password.match_password_required',
                    })
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password
            size='large'
            className={styles.mail_input}
            iconRender={(visible) =>
              visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
            }
          />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='forgot_password.verify_with_email' />}
          name='confirmation_otp'
          rules={[
            {
              required: true,
              message: <FormattedMessage id='forgot_password.otp_required' />,
            },
          ]}
        >
          <Input size='large' className={styles.mail_input} />
        </Form.Item>

        <Form.Item>
          <Button className={styles.send_mail_button} htmlType='submit' loading={loading}>
            <FormattedMessage id='forgot_password.reset_password' />
          </Button>
        </Form.Item>
        {resendTime !== 0 && (
          <div className={styles.resend_code}>
            (<FormattedMessage id='forgot_password.resend_in' />
            {` ${resendTime})`}
          </div>
        )}
        {resendTime === 0 && (
          <div className={styles.resend_code}>
            <Button onClick={resendCode} className='width-150 mr-4' type='link' loading={isCallingResend}>
              <FormattedMessage id='forgot_password.resend_code' />
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default RecaptchaComponent(withGoogleReCaptcha(SignupStep2));
