/**
 *
 * NavBar
 *
 */
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { activeTabNavBarSelector } from 'redux/navbar/reducers';
import { SET_NAV_BAR_ACTIVE_TAB } from 'redux/navbar/actions';
import { NavButton } from './NavButton';
import RoutesList, { DASHBOARD_TAB_NAV_ID, REFERRAL_TAB_NAV_ID, STAKING_TAB_NAV_ID } from './routes';

import styles from './style.module.scss';

export function NavBar() {
  const activeTab = useSelector(activeTabNavBarSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const MappedRoutesList = useMemo(
    () =>
      RoutesList.map((route) => ({
        ...route,
        label: <FormattedMessage id={route.label} />,
      })),
    []
  );

  useEffect(() => {
    let targetActiveTab = DASHBOARD_TAB_NAV_ID;
    if (location) {
      const { pathname } = location;
      if (/^\/dashboard(?=\/|$)/i.test(pathname)) {
        targetActiveTab = DASHBOARD_TAB_NAV_ID;
      }
      if (/^\/referral-manager(?=\/|$)/i.test(pathname)) {
        targetActiveTab = REFERRAL_TAB_NAV_ID;
      }
      if (/^\/staking(?=\/|$)/i.test(pathname)) {
        targetActiveTab = STAKING_TAB_NAV_ID;
      }
    }
    dispatch({ type: SET_NAV_BAR_ACTIVE_TAB, payload: targetActiveTab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={styles.navbar_wrapper}>
      {MappedRoutesList.map((route) => (
        <NavButton routeInfo={route} active={activeTab === route.id} key={route.label} />
      ))}
    </div>
  );
}
