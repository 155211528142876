/**
 *
 * StakePage
 *
 */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'antd';
import lodash from 'lodash';
import ProgramItem from 'components/ProgramItem';
import { useHistory } from 'react-router';
import StakeItem from 'components/StakeItem';
import { getWallet } from 'services/user';
import { getStakeProgramsList, getFetchStatus as getProgramFetchStatus } from 'redux/stake-program/reducers';
import { getRoundSetting } from 'redux/staking-round-config/reducers';
import { FETCH_STAKE_PROGRAM_LIST } from 'redux/stake-program/actions';
import { FETCH_STAKING_ROUND_SETTING } from 'redux/staking-round-config/actions';
import { getStakeList, getFetchStatus as getStakeFetchStatus } from 'redux/stake/reducers';
import { FETCH_STAKE_LIST } from 'redux/stake/actions';
import { showError } from 'utils/toastMessage';
import { FormattedMessage } from 'react-intl';
import NavigationTabSwitch from 'components/NavigationTabSwitch';
import ProgramView from './ProgramView';
import styles from './style.module.scss';

const StakePage = (props) => {
  const { activeTab } = props;
  const [view, setView] = useState('home');
  const stakeProgramsList = useSelector(getStakeProgramsList);
  const roundSetting = useSelector(getRoundSetting);
  const user = useSelector((state) => state.user);
  const stakesList = useSelector(getStakeList);
  const programFetchStatus = useSelector(getProgramFetchStatus);
  const stakeFetchStatus = useSelector(getStakeFetchStatus);
  const dispatch = useDispatch();
  const [currentProgram, setCurrentProgram] = useState();
  const [currentStakePage, setCurrentStakePage] = useState(1);
  const [currentProgramPage, setCurrentProgramPage] = useState(1);
  const [walletAddress, setWalletAddress] = useState('');
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const onChoseProgram = useCallback((program) => {
    // const { ended } = program;
    // if (!ended) {
    //   setCurrentProgram(program);
    //   setView('program');
    // }
  }, []);

  const onChoseStake = useCallback(
    (stake) => {
      history.push(`/staking/${stake.id}`);
    },
    [history]
  );

  const fetchWallet = async () => {
    // const { dispatch } = this.props;
    try {
      const res = await getWallet();
      if (res.data.error) {
        showError(lodash.get(res, 'data.message.messages[0].id'));
      } else {
        const { wallet_address } = res.data;
        setWalletAddress(wallet_address);
      }
    } catch (err) {
      console.log(err);
      // dispatch({
      //   type: SET_ERROR,
      //   payload: {
      //     error: 'Network error',
      //   },
      // });
    }
  };

  useEffect(() => {
    fetchWallet();
  }, []);

  useEffect(() => {
    if (activeTab === '1') {
      dispatch({
        type: FETCH_STAKE_PROGRAM_LIST.REQUEST,
        payload: {
          offset: 0,
          limit: 20,
          locale: user.locale,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.locale]);

  useEffect(() => {
    if (currentProgram) {
      const newProgram = stakeProgramsList.find((program) => program.type === currentProgram.type);
      if (newProgram) {
        setCurrentProgram(newProgram);
      } else {
        setView('home');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeProgramsList]);

  useEffect(() => {
    if (activeTab === '2') {
      dispatch({
        type: FETCH_STAKE_LIST.REQUEST,
        payload: {
          offset: 0,
          limit: 20,
        },
      });
    }
  }, [activeTab, dispatch]);

  useEffect(() => {
    if (!roundSetting.sale_start_time) {
      dispatch({
        type: FETCH_STAKING_ROUND_SETTING.REQUEST,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreStake = useCallback(
    (limit) => {
      dispatch({
        type: FETCH_STAKE_LIST.REQUEST,
        payload: {
          offset: stakeFetchStatus.currentOffset,
          limit,
        },
      });
    },
    [dispatch, stakeFetchStatus]
  );

  const loadMoreProgram = useCallback(
    (limit) => {
      dispatch({
        type: FETCH_STAKE_PROGRAM_LIST.REQUEST,
        payload: {
          offset: programFetchStatus.currentOffset,
          limit,
          locale: user.locale,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, programFetchStatus]
  );

  const onProgramPageChange = useCallback(
    (pageNumber) => {
      setCurrentProgramPage(pageNumber);
      const limit = pageNumber * 5 - programFetchStatus.currentOffset;
      if (limit > 0) {
        loadMoreProgram(limit);
      }
    },
    [setCurrentProgramPage, loadMoreProgram, programFetchStatus]
  );

  const onStakePageChange = useCallback(
    (pageNumber) => {
      setCurrentStakePage(pageNumber);
      const limit = pageNumber * 5 - stakeFetchStatus.currentOffset;
      if (limit > 0) {
        loadMoreStake(limit);
      }
    },
    [setCurrentStakePage, loadMoreStake, stakeFetchStatus]
  );

  if (view === 'program') {
    return (
      <div className={styles.staking_page}>
        <div className={styles.block}>
          <div className={styles.tab_section}>
            <NavigationTabSwitch />
            <div className={styles.inner}>
              <div className={styles.back_btn_wrapper}>
                <button className={styles.back_btn} type='button' onClick={() => setView('home')}>
                  <img src='/images/left-arrow.svg' alt='back' />
                  <FormattedMessage id='create_staking.back' />
                </button>
              </div>

              <div
                style={{
                  marginTop: 10,
                }}
                className={styles.align_center}
              >
                <ProgramView
                  program={currentProgram}
                  walletAddress={walletAddress}
                  roundSetting={roundSetting}
                  back={() => setView('home')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.staking_page}>
      <div className={styles.block}>
        <div className={styles.tab_section}>
          <NavigationTabSwitch />
          <div className={styles.inner}>
            <div className={styles.tab_switcher}>
              <button
                className={activeTab === '1' ? styles.active_btn : styles.inactive_btn}
                type='button'
                onClick={() => history.push(`/staking/program-list`)}
              >
                <FormattedMessage id='create_staking.program_list' />
              </button>
              <button
                type='button'
                onClick={() => history.push(`/staking/staking-list`)}
                className={activeTab === '2' ? styles.active_btn : styles.inactive_btn}
              >
                <FormattedMessage id='create_staking.current_staking' />
              </button>
            </div>
            <div
              style={{
                marginTop: 10,
              }}
              className={styles.align_center}
            >
              {activeTab === '1' && (
                <div
                  style={{
                    width: '100%',
                    maxWidth: 735,
                  }}
                >
                  <div className={styles.delay_message}>
                    <FormattedMessage id='create_staking.changed_message' values={{ br: <br /> }} />
                    <a href='https://bhopad.io/staking' target='_blank' rel='noreferrer'>
                      https://bhopad.io/staking
                    </a>
                  </div>
                  <List
                    style={{
                      width: '100%',
                      maxWidth: 735,
                    }}
                    itemLayout='vertical'
                    size='small'
                    loading={programFetchStatus.isFetching}
                    pagination={{
                      hideOnSinglePage: true,
                      size: 'small',
                      pageSize: 5,
                      defaultCurrent: currentProgramPage,
                      onChange: onProgramPageChange,
                      total: programFetchStatus.total,
                    }}
                    dataSource={stakeProgramsList}
                    renderItem={(item) => (
                      <List.Item key={item.onchain_id}>
                        <ProgramItem program={item} onClick={() => onChoseProgram(item)} />
                      </List.Item>
                    )}
                  />
                </div>
              )}
              {activeTab === '2' && (
                <div
                  style={{
                    width: '100%',
                    maxWidth: 735,
                  }}
                >
                  <div className={styles.delay_message}>
                    <FormattedMessage id='create_staking.delay_message' values={{ br: <br /> }} />
                  </div>
                  <List
                    style={{
                      width: '100%',
                      maxWidth: 735,
                    }}
                    itemLayout='vertical'
                    size='small'
                    loading={stakeFetchStatus.isFetching}
                    pagination={{
                      hideOnSinglePage: true,
                      size: 'small',
                      pageSize: 5,
                      defaultCurrent: currentStakePage,
                      onChange: onStakePageChange,
                      total: stakeFetchStatus.total,
                    }}
                    dataSource={stakesList}
                    renderItem={(item) => (
                      <List.Item key={item.onchain_id}>
                        <StakeItem stake={item} onClick={() => onChoseStake(item)} />
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakePage;
