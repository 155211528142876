import { combineReducers } from 'redux';
import { appendByIds } from 'utils/convertData';
import actions from 'redux/user/actions';
import { FETCH_COMMISSION_LIST, FETCH_COMMISSION_STATISTICS, FETCH_COMMISSION_SCHEME } from './actions';

const initialState = {
  // NOTE: null is not fetch api yet
  isFetching: null,
  total: 0,
  currentOffset: 0,
  total_referrals: 0,
  total_commissions: '0', // in USD
};

export const fetchStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMISSION_LIST.PENDING:
      return { ...state, isFetching: true };
    case FETCH_COMMISSION_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentOffset: action.payload.currentOffset,
        total: action.payload.total,
      };
    case FETCH_COMMISSION_LIST.ERROR:
      return { ...state, isFetching: false };
    case FETCH_COMMISSION_STATISTICS.PENDING:
      return { ...state, isFetching: true };
    case FETCH_COMMISSION_STATISTICS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        total_referrals: action.payload.total_referrals,
        total_commissions: action.payload.total_commissions,
      };
    case FETCH_COMMISSION_STATISTICS.ERROR:
      return { ...state, isFetching: false };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMMISSION_LIST.SUCCESS:
      return appendByIds(state, action.payload.data, 'id');
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_COMMISSION_LIST.SUCCESS:
      return [...state, ...action.payload.data.map((item) => item.id)].filter(
        (value, index, self) => self.indexOf(value) === index
      );
    case actions.LOGOUT:
      return [];
    default:
      return state;
  }
};

const initialSchemeState = {
  isFetching: false,
  data: {},
};

export const scheme = (state = initialSchemeState, action) => {
  switch (action.type) {
    case FETCH_COMMISSION_SCHEME.PENDING:
      return { ...state, isFetching: true };
    case FETCH_COMMISSION_SCHEME.ERROR:
      return { ...state, isFetching: false };
    case FETCH_COMMISSION_SCHEME.SUCCESS:
      return { ...state, isFetching: false, ...action.payload.data };
    case actions.LOGOUT:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId,
  scheme,
});

// Selector here
export const getCommissionList = (state) => state.commission.visibleIds.map((id) => state.commission.byId[id]);
export const getFetchStatus = (state) => state.commission.fetchStatus;
export const getScheme = (state) => state.commission.scheme;
