import React from 'react';
import classNames from 'classnames';
import Countdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const renderCountDownCard = (props) => (
  <>
    <div className={styles.countdown_card}>
      <div className={styles.countdown_number}>{props.days}</div>
      <div className={styles.countdown_title}>
        <FormattedMessage id='countdown.days' />
      </div>
    </div>
    <div className={styles.countdown_card}>
      <div className={styles.countdown_number}>{props.hours}</div>
      <div className={styles.countdown_title}>
        <FormattedMessage id='countdown.hours' />
      </div>
    </div>
    <div className={styles.countdown_card}>
      <div className={styles.countdown_number}>{props.minutes}</div>
      <div className={styles.countdown_title}>
        <FormattedMessage id='countdown.mins' />
      </div>
    </div>
    <div className={styles.countdown_card}>
      <div className={styles.countdown_number}>{props.seconds}</div>
      <div className={styles.countdown_title}>
        <FormattedMessage id='countdown.secs' />
      </div>
    </div>
  </>
);

const renderCountDownText = ({ days, hours, minutes, seconds }) => (
  <div className={styles.countdown_time}>
    {days}d:{hours}h:{minutes}m:{seconds}s
  </div>
);

const CountDown = ({ className = '', dateTime, onComplete = () => {}, card = true }) => {
  const date = dayjs(dateTime).valueOf();
  return (
    <div className={classNames(styles.countdown_container, className)}>
      <Countdown
        // NOTE: key help fix Countdown not refresh following https://github.com/ndresx/react-countdown/issues/144
        key={date}
        date={date}
        renderer={card ? renderCountDownCard : renderCountDownText}
        onComplete={onComplete}
      />
    </div>
  );
};

export default CountDown;
