/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import lodash from 'lodash';
import { login, getMe, register, confirmOTP, submitKYC, addWallet, updateProfile } from 'services/user';
import history from 'routerHistory';
import { showError, showSuccess } from 'utils/toastMessage';
// import { FETCH_PAYMENT_METHOD_LIST } from "redux/payment-method/actions";
// import { SET_ERROR } from 'redux/error/actions';
import { FETCH_PAYMENT_METHOD_LIST } from 'redux/payment-method/actions';
import analytics from 'utils/analytics';
import { BASE_URL } from 'configs';
import actions from './actions';
import { store as storeRedux } from '../../store';

export function* REGISTER({ payload }) {
  const { username, password, first_name, last_name, locale, referral_code, referral_type, captchaToken } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(
      register,
      username,
      password,
      first_name,
      last_name,
      locale,
      referral_code,
      referral_type,
      captchaToken
    );

    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});
      const { infobip_jwt } = res.data;
      window.liveChat('auth', infobip_jwt);
      showSuccess('signupSuccess');
      // store.set('locale', user.locale);
      yield analytics.sendEvent({
        action: 'signup_success_action',
        label: 'request_success',
      });
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          authorized: true,
          loading: false,
        },
      });
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* CONFIRM_OTP({ payload }) {
  const { username, confirmation_otp, captchaToken } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(confirmOTP, username, confirmation_otp, captchaToken);

    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});
      showSuccess('verifiedEmailSuccess');
      yield analytics.sendEvent({
        action: 'verify_otp_success_action',
        label: 'request_success',
      });
      const { jwt } = res.data;
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          jwt_token: jwt,
          authorized: true,
          loading: false,
        },
      });
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGIN({ payload }) {
  const { username, password, captchaToken } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(login, username, password, captchaToken);
    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});

      yield analytics.sendEvent({
        action: 'login_success_action',
        label: 'request_success',
      });
      const { jwt, infobip_jwt } = res.data;
      window.liveChat('auth', infobip_jwt);

      yield put({ type: actions.LOGIN_SUCCESS });
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          infobip_jwt,
          jwt_token: jwt,
          authorized: true,
          loading: false,
        },
      });
      if (lodash.get(user, 'account_status') === 'unverified') {
        history.push(`${BASE_URL}/verify-otp`);
      } else {
        history.push(`${BASE_URL}/staking`);
      }
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  // const token = store.get('jwt_token');
  const token = lodash.get(storeRedux.getState(), 'user.jwt_token');
  if (token !== '') {
    try {
      yield put({
        type: 'SET_STATE',
        payload: {
          loading: true,
        },
      });

      const res = yield call(getMe);
      const { infobip_jwt } = res.data;
      window.liveChat('auth', infobip_jwt);
      if (!res.data.error) {
        const user = lodash.get(res, 'data.data', {});
        // store.set('locale', user.locale);
        yield put({
          type: actions.SET_STATE,
          payload: {
            ...user,
            infobip_jwt,
            authorized: true,
            loading: false,
          },
        });
      } else {
        showError('Session timeout! Please login again!');
        yield put({
          type: actions.SET_STATE,
          payload: {
            authorized: false,
            loading: false,
          },
        });
      }
    } catch (err) {
      // yield put({
      //   type: SET_ERROR,
      //   payload: {
      //     error: 'Network error',
      //   },
      // });
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        authorized: false,
        loading: false,
      },
    });
  }
}

export function* KYC({ payload }) {
  const { kyc, documents } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(submitKYC, kyc, documents);

    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});
      showSuccess('submitKYCSuccess');
      yield analytics.sendEvent({
        action: 'kyc_success_action',
        label: 'request_success',
      });
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          loading: false,
        },
      });
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* ADD_WALLET({ payload }) {
  const { wallet_address } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(addWallet, wallet_address);

    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});
      showSuccess('addWalletSuccess');
      yield analytics.sendEvent({
        action: 'add_wallet_success_action',
        label: 'request_success',
      });
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          loading: false,
        },
      });
      if (BASE_URL !== '') {
        history.push(`${BASE_URL}/thank-you`);
      } else {
        history.push(`${BASE_URL}/staking`);
      }
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* UPDATE_PROFILE({ payload }) {
  const { data } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const res = yield call(updateProfile, data);
    if (!res.data.error) {
      const user = lodash.get(res, 'data.data', {});
      if (data.locale) {
        if (
          history.location.pathname.indexOf('/dashboard') > -1 ||
          history.location.pathname.indexOf('/referral-manager') > -1 ||
          history.location.pathname.indexOf('/create-request') > -1
        ) {
          if (!data.isSavedPaymentLocaleMatched) {
            yield put({
              type: FETCH_PAYMENT_METHOD_LIST.REQUEST,
              payload: {
                offset: 0,
                limit: -1,
                locale: data.locale,
              },
            });
          } else {
            yield put({
              type: FETCH_PAYMENT_METHOD_LIST.SUCCESS,
              payload: {
                data: data.existMatchedPaymentMethodList,
                // NOTE: total do not use
                // total: data.existMatchedPaymentMethodList.length,
                savedAt: new Date().toISOString(),
              },
            });
          }
        }
      }

      yield put({
        type: actions.SET_STATE,
        payload: {
          ...user,
          loading: false,
        },
      });
    } else {
      showError(lodash.get(res, 'data.message.messages[0].id'));
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
    }
  } catch (error) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  try {
    // yield call(logout);
    // store.remove('jwt_token');
    window.liveChat('logout', null);
    yield analytics.sendEvent({
      action: 'logout_success_action',
      label: 'request_success',
    });
    yield put({
      type: actions.RESET,
    });
    history.push(`${BASE_URL}/login`);
  } catch (err) {
    console.log(err);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.CONFIRM_OTP, CONFIRM_OTP),
    takeEvery(actions.SUBMIT_KYC, KYC),
    takeEvery(actions.ADD_WALLET, ADD_WALLET),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
  ]);
}
