import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
import styles from './style.module.scss';

export default class NotFoundPage extends PureComponent {
  render() {
    return (
      <div
        style={{
          width: '100%',
          minHeight: '90vh',

          backgroundImage: `url('${BASE_URL}/images/404-background.png')`,
          backgroundSize: 'cover',
        }}
      >
        <Helmet title='Not Found' />
        <div className={styles.content}>
          <div className={styles.message}>$Oops...looks like you are lost</div>
          <div>
            <Image src='/images/404.png' className={styles.content_image} alt='404' />
          </div>
          <Link to={`${BASE_URL}/`}>
            <Button className={styles.button}>Go home</Button>
          </Link>
        </div>
      </div>
    );
  }
}
