import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
import styles from './style.module.scss';

const { Header, Content, Footer } = Layout;

class ErrorLayout extends React.PureComponent {
  render() {
    const { children, user } = this.props;
    return (
      <Layout
        className={styles.main}
        style={{
          background: `url("${BASE_URL}/images/network-background.png") no-repeat center center  scroll`,
        }}
      >
        <Header className={styles.header}>
          <div className={styles.logo}>
            <Link to={user.authorized ? `${BASE_URL}/dashboard` : `${BASE_URL}/login`}>
              <Image src='/images/logo-b.png' alt='BHO Network' />
            </Link>
          </div>
        </Header>
        <Content className={styles.layout}>{children}</Content>
        <Footer style={{ textAlign: 'center' }}>©2021 Copyright by BHO Network</Footer>
        <div />
      </Layout>
    );
  }
}

export default ErrorLayout;
