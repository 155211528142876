import { combineReducers } from 'redux';
import actions from 'redux/user/actions';
import { FETCH_BALANCE } from './actions';

const initialState = {
  isFetching: false,
  usd: 0, // USD
  bho: 0, // BHO
};

export const fetchBalance = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BALANCE.PENDING:
      return { ...state, isFetching: true };
    case FETCH_BALANCE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        usd: action.payload.total_in_amount,
        bho: action.payload.total_out_amount,
      };
    case FETCH_BALANCE.ERROR:
      return { ...state, isFetching: false };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  fetchBalance,
});

// Selector here
export const getBalance = (state) => state.balance.fetchBalance;
