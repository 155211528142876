import { combineReducers } from 'redux';
import { updateById, appendByIdsUpdateOld, addKeyToObjects } from 'utils/convertData';
import actions from 'redux/user/actions';
import {
  FETCH_TRANSACTION_DETAIL,
  FETCH_TRANSACTION_LIST,
  CREATE_TRANSACTION,
  CONFIRM_PAYMENT,
  CANCEL_TRANSACTION,
  FETCH_CURRENT_TOTAL,
} from './actions';

const initialState = {
  isFetching: false,
  currentOffset: 0,
  total: 0,
  transaction_currency: null,
  sett_currency: null,
};

export const fetchStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LIST.PENDING:
      return { ...state, isFetching: true };
    case FETCH_TRANSACTION_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentOffset: action.payload.currentOffset,
        total: action.payload.total,
        transaction_currency: action.payload.transaction_currency,
        sett_currency: action.payload.sett_currency,
      };
    case FETCH_TRANSACTION_LIST.ERROR:
      return { ...state, isFetching: false };

    case CREATE_TRANSACTION.PENDING:
      return { ...state, isFetching: true };
    case CREATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_TRANSACTION.ERROR:
      return { ...state, isFetching: false };
    case CONFIRM_PAYMENT.PENDING:
      return { ...state, isFetching: true };
    case CONFIRM_PAYMENT.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case CONFIRM_PAYMENT.ERROR:
      return { ...state, isFetching: false };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LIST.SUCCESS:
      return appendByIdsUpdateOld(state, action.payload.data, 'id');
    case FETCH_TRANSACTION_DETAIL.SUCCESS: {
      return updateById(state, action.payload.data, 'id');
    }
    case CANCEL_TRANSACTION.SUCCESS: {
      return updateById(state, action.payload.data, 'id');
    }
    case CONFIRM_PAYMENT.SUCCESS: {
      return updateById(state, action.payload.data, 'id');
    }
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LIST.SUCCESS: {
      if (action.payload.currentOffset === 20) {
        return action.payload.data.map((item) => item.id);
      }
      return [...state, ...action.payload.data.map((item) => item.id)].filter(
        (value, index, self) => self.indexOf(value) === index
      );
    }
    case FETCH_TRANSACTION_DETAIL.SUCCESS:
      return [...state, action.payload.data.id].filter((value, index, self) => self.indexOf(value) === index);
    case actions.LOGOUT:
      return [];
    default:
      return state;
  }
};

const initialTotalState = {
  isFetching: false,
  // NOTE: = null mean not load yet
  max_allowed_buy_amount: null,
  total_in_amount: 0,
  total_out_amount: 0,
};

const fetchTotal = (state = initialTotalState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_TOTAL.PENDING:
      return { ...state, isFetching: true };
    case FETCH_CURRENT_TOTAL.SUCCESS:
      return {
        isFetching: false,
        ...action.payload,
        savedAt: new Date().toISOString(),
      };
    case FETCH_CURRENT_TOTAL.ERROR:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds,
  fetchTotal,
});

export const getTransactionList = (state) => state.transaction.visibleIds.map((id) => state.transaction.byId[id]);

export const getFetchStatus = (state) => state.transaction.fetchStatus;
export const getTransactionDetail = (state, transactionId) => {
  const data = addKeyToObjects(state.transaction.fetchStatus.total, getTransactionList(state), 'id', true, true);
  // eslint-disable-next-line radix
  return data.find((item) => item.id === parseInt(transactionId));
};
export const getFetchTotal = (state) => state.transaction.fetchTotal;
