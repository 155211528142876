import React, { Component } from 'react';
import { Table, Form, Input, Button } from 'antd';
import lodash from 'lodash';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BASE_URL, BSC_SCAN_URL } from 'configs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getTransactionDetail, getFetchStatus } from 'redux/transaction/reducers';
import {
  FETCH_TRANSACTION_DETAIL,
  // FETCH_TRANSACTION_DETAIL,
  // FETCH_TRANSACTION_LIST,
} from 'redux/transaction/actions';

import styles from './style.module.scss';

dayjs.extend(advancedFormat);

const statusColor = {
  pending: 'white',
  released: '#1FC7D4',
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: false,
      payment_slip: '',
      confirm_type: 'payment_slip',
      transaction_address: '',
      checkbox: true,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const { data, dispatch, match } = this.props;
    if (!data) {
      dispatch({
        type: FETCH_TRANSACTION_DETAIL.REQUEST,
        payload: { id: match.params.id },
      });
      // dispatch({
      //   type: FETCH_TRANSACTION_LIST.REQUEST,
      //   payload: {
      //     offset: 0,
      //     limit: 999,
      //   },
      // });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { data, fetchStatus } = props;
    const { initData } = state;
    // isFetching for waiting asynchronous store update when editing
    if (data && !initData && !fetchStatus.isFetching) {
      return {
        ...state,
        payment_slip: lodash.get(data, 'payment.payment_slip'),
        confirm_type: lodash.get(data, 'payment.payment_slip') ? 'payment_slip' : 'transaction_address',
        transaction_address: lodash.get(data, 'payment.transaction_address'),
        checkbox: !lodash.get(data, 'payment.payment_slip') && !lodash.get(data, 'payment.transaction_address'),
        initData: true,
      };
    }
    return state;
  }

  render() {
    const { data, fetchStatus } = this.props;
    const data_lockup = lodash.get(data, 'transaction_locks', []);
    const tableColumns = [
      {
        title: <FormattedMessage id='request_detail.timeline' />,
        dataIndex: 'release_date',
        align: 'left',
        render: (date) => `${dayjs(date).format('MM/DD/YYYY')}`,
        width: 30,
      },
      {
        title: <FormattedMessage id='request_detail.information' />,
        dataIndex: ['release_percent', 'amount', 'release_transaction_address'],
        align: 'left',
        render: (text, records) => (
          <>
            <div>
              <FormattedMessage id='request_detail.release' />: {records.release_percent}% - {records.amount} BHO
            </div>
            <div style={styles.table_content}>
              Transaction ID:{' '}
              {lodash.get(records, 'release_transaction_address', '') ? (
                <Link
                  to={{
                    pathname: `${BSC_SCAN_URL}/tx/${records.release_transaction_address}`,
                  }}
                  target='_blank'
                  style={{ color: '#1FC7D4' }}
                >
                  {lodash.get(records, 'release_transaction_address', '').substring(0, 25)}
                  ...
                </Link>
              ) : null}
            </div>
          </>
        ),
        width: 50,
      },
      {
        title: <FormattedMessage id='request_detail.lock_status' />,
        dataIndex: 'status',
        align: 'center',
        render: (status) => (
          <span style={{ color: statusColor[status], fontWeight: 'bold', textTransform: 'capitalize' }}>
            <FormattedMessage id={`request_detail.${status}`} />
          </span>
        ),
        width: 20,
      },
    ];

    return (
      <div className={styles.request_detail}>
        <Helmet title='Request Detail' />

        <div className={styles.block}>
          <div className={styles.inner}>
            <div className={styles.back_arrow_wrapper}>
              <Link className={styles.back_arrow} to={`${BASE_URL}/dashboard`}>
                <ArrowLeftOutlined />
                Back
              </Link>
            </div>
            <div className={styles.title}>
              <FormattedMessage id='request_detail.transaction' />
              {`: #${lodash.get(data, 'id')}`}
            </div>
            <Form layout='vertical' hideRequiredMark className={styles.form} onFinish={this.onFinish}>
              <div className={styles.input_group} style={{ marginTop: '10px' }}>
                <Form.Item
                  label={<FormattedMessage id='request_detail.your_wallet_address' />}
                  className={styles.address_input_wrapper}
                >
                  <Input className={styles.number_input} value={lodash.get(data, 'buyer_wallet')} disabled />
                </Form.Item>
              </div>
              <div className={styles.input_group} style={{ marginTop: '10px' }}>
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage id='request_detail.you_buy' />
                    </span>
                  }
                  className={styles.address_input_wrapper}
                >
                  <Input className={styles.number_input} disabled value={lodash.get(data, 'transaction.out_amount')} />
                </Form.Item>
              </div>
              <div className={styles.input_group} style={{ marginTop: '10px' }}>
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage id='request_detail.contract' />
                    </span>
                  }
                  className={styles.address_input_wrapper}
                >
                  <Input
                    className={`${styles.number_input} ${styles.contract_input}`}
                    disabled
                    value={lodash.get(data, 'lock_contract_address')}
                    suffix={
                      <Link
                        className={styles.look_up}
                        to={{
                          pathname: `${BSC_SCAN_URL}/address/${lodash.get(data, 'lock_contract_address')}`,
                        }}
                        target='_blank'
                      >
                        <SearchOutlined style={{ fontSize: '16px', color: '#fff' }} />
                      </Link>
                    }
                  />
                </Form.Item>
              </div>
            </Form>
            <div className={styles.table_section}>
              <Table
                loading={fetchStatus.isFetching}
                className='table__scrollTable'
                scroll={{ x: 'max-content' }}
                dataSource={lodash.orderBy(data_lockup, ['index'], ['asc'])}
                columns={tableColumns}
                rowKey={(record) => record.id}
                bordered
                rowClassName={(record, index) => (index % 2 === 0 ? styles.table_row_dark : styles.table_row_light)}
                pagination={{
                  hideOnSinglePage: true,
                  size: 'small',
                  pageSize: 6,
                  position: ['bottomCenter'],
                  className: styles.table_pagination,
                }}
                tableLayout='fixed'
              />
            </div>
          </div>

          {lodash.get(data, 'status') !== 'pending' && (
            <div className={styles.btn_group}>
              <Link to={`${BASE_URL}/dashboard`}>
                <Button className={styles.back_dashboard_button} loading={fetchStatus.isFetching}>
                  <FormattedMessage id='request_detail.back_to_dashboard' />
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  data: getTransactionDetail(state, props.match.params.id),
  fetchStatus: getFetchStatus(state),
});

export default withRouter(injectIntl(connect(mapStateToProps)(Dashboard)));
