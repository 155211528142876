import { UPDATE_SALE_STATISTICS } from './actions';

const initialState = {
  total_token_distribution: '1', // total BHO distributed
  total_sold_tokens: '0', // total BHO sold
  total_sold: '0', // total $ sold
};

export const saleStatistics = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SALE_STATISTICS.SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default saleStatistics;

export const getSaleStatistics = (state) => state.saleStatistics;
