/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from 'redux/user/reducers';
import classNames from 'classnames';
import styles from './style.module.scss';
import { ReactComponent as Tag } from './end-tag.svg';

const ProgramItem = (props) => {
  const { program, onClick } = props;
  const { type, value_locked, apy, min_limit } = program;
  const ended = true;
  const currentLocale = useSelector(getCurrentLocale);

  const getNumberOfProgram = () => {
    switch (type) {
      case 'program_1':
        return '1';
      case 'program_2':
        return '2';
      default:
        return '3';
    }
  };

  return (
    <div className={classNames(styles.program_item_wrapper, { [styles.program_item_ended]: ended })} onClick={onClick}>
      <div
        className={styles.flex_row}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '80%',
        }}
      >
        {!isMobile && <img src='/images/bho_token.svg' alt='bho' />}

        <div
          className={styles.flex_col}
          style={{
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            marginLeft: 20,
            height: '100%',
          }}
        >
          <div className={styles.program_title}>
            <FormattedMessage id='create_staking.program' /> {getNumberOfProgram()}
          </div>
          <div className={styles.bold_text}>
            <FormattedMessage id='create_staking.capital_return_after_90_day' />
          </div>
        </div>
      </div>
      <div
        className={styles.flex_col}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
          height: '80%',
          minWidth: currentLocale === 'vi' ? '160px' : '222px',
        }}
      >
        <div
          className={styles.flex_row}
          style={{
            alignItems: 'baseline',
          }}
        >
          {apy}% APY
        </div>
        <div className={styles.bold_text}>
          <FormattedMessage
            id={
              type === 'program_2'
                ? 'create_staking.interest_pay_after_every_30_day'
                : 'create_staking.interest_pay_after_90_day'
            }
          />
        </div>
      </div>
      <div
        className={styles.flex_col}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
          height: '80%',
        }}
      >
        <div>
          <FormattedMessage id='create_staking.value_locked' />
        </div>
        <div className={styles.bold_text}>
          <FormattedNumber value={Math.floor(value_locked)} />
        </div>
      </div>
      <div
        className={styles.flex_col}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
          height: '80%',
        }}
      >
        <div>
          <FormattedMessage id='create_staking.min_amount' />
        </div>
        <div className={styles.bold_text}>
          <FormattedNumber value={Math.floor(min_limit)} />
        </div>
      </div>
      {ended ? (
        <div className={styles.tag}>
          <Tag />
        </div>
      ) : null}
    </div>
  );
};

export default ProgramItem;
