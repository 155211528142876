import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

const DOMAIN = 'payment-methods';

export async function getList(offset = 0, limit = 20, locale = 'en') {
  const url = `${BASE_API_URL}/${DOMAIN}?_start=${offset}&_limit=${limit}&_locale=${locale}`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
