import React, { useEffect, useState } from 'react';
import { Input, Form, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import lodash from 'lodash';
import {
  // showError,
  showSuccess,
} from 'utils/toastMessage';
import CopyButton from 'components/CopyButton';

import styles from './style.module.scss';

const ConfirmView = (props) => {
  // const dispatch = useDispatch();
  const { program, cancel, walletAddress, walletClicked, backToProgramView } = props;
  const [newWallet, setNewWallet] = useState('');

  const getNumberOfProgram = (type) => {
    switch (type) {
      case 'program_1':
        return '1';
      case 'program_2':
        return '2';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isMobile) {
      const beginBlockStep2 = document.getElementById('begin-block-step-2');

      if (beginBlockStep2) {
        beginBlockStep2.scrollIntoView();
      }
    }
  }, []);
  const onFinish = () => {
    showSuccess('createStakingSuccessfully');
    backToProgramView();
  };

  return (
    <div id='begin-block-step-2' className={styles.confirm_view}>
      <div className={styles.inner}>
        <Form
          layout='vertical'
          hideRequiredMark
          onFinish={onFinish}
          className={`${styles.form} ${styles.form_margin_top}`}
        >
          <div className={styles.input_group} style={{ marginTop: '10px' }}>
            <Form.Item
              label={
                <div>
                  <FormattedMessage
                    id={`create_staking.your${walletClicked === 'trust' ? '_trust' : ''}_wallet_address`}
                  />{' '}
                  <span className={styles.note_text}>
                    <FormattedMessage
                      id={`create_staking.your${walletClicked === 'trust' ? '_trust' : ''}_wallet_address_note`}
                    />
                  </span>
                </div>
              }
              className={styles.address_input_wrapper}
            >
              <Input
                onChange={(e) => setNewWallet(e.target.value)}
                className={styles.number_input}
                value={newWallet || walletAddress}
                disabled
              />
            </Form.Item>
          </div>
          <div className={styles.input_group} style={{ marginTop: '10px' }}>
            <Form.Item
              label={
                <div>
                  <FormattedMessage id='create_staking.our_wallet_address' />{' '}
                  <FormattedMessage id='create_staking.our_wallet_address_staking_program' />{' '}
                  {getNumberOfProgram(lodash.get(program, 'type'))}{' '}
                  <CopyButton content={lodash.get(program, 'wallet')} withText />{' '}
                  <span className={styles.note_text}>
                    <FormattedMessage id='create_staking.our_wallet_address_note' />
                  </span>
                </div>
              }
              className={styles.address_input_wrapper}
            >
              <Input
                className={`${styles.number_input} ${styles.number_input_disable}`}
                disabled
                value={lodash.get(program, 'wallet')}
              />
            </Form.Item>
          </div>
        </Form>
        <div className={styles.warning_section}>
          <p style={{ fontWeight: 'bold' }}>
            <FormattedMessage id='create_staking.warning' />
          </p>
          <div>{lodash.get(program, 'additional_information')}</div>
        </div>
        <div className={styles.final_action_text}>
          <FormattedMessage id='create_staking.final_action_text' /> &quot;
          <FormattedMessage id='create_staking.confirm_request' />
          &quot;
        </div>
      </div>
      <div className={styles.btn_group}>
        <Button className={`${styles.buy_button} ${styles.cancel_button}`} onClick={cancel}>
          <FormattedMessage id='create_staking.cancel' />
        </Button>
        <Button className={styles.buy_button} onClick={onFinish}>
          <FormattedMessage id='create_staking.confirm_request' />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmView;
