import antdData from 'antd/lib/locale/en_US';

const messages = {
  'guide.url': 'https://bholdus.github.io/bholdus-resources/Buy_Bholdus_[EN].pdf',
  'guide.text': 'How to buy BHO?',
  'document_type.national_id': 'ID',
  'document_type.driver_license': 'Driver License',
  'document_type.passport': 'Passport',

  // navbar
  'navbar.dashboard': 'Dashboard',
  'navbar.referral': 'Referral',
  'navbar.staking': 'Staking',

  // button
  'button.copy': 'Copy',

  // profile menu
  'profile_menu.hello': 'Hello',
  'profile_menu.wallet': 'Wallet',
  'profile_menu.change_password': 'Change password',
  'profile_menu.logout': 'Logout',

  // login
  'login.title': 'LOGIN',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.email_required': 'Please input your email',
  'login.email_invalid': 'The input is not valid E-mail',
  'login.password_required': 'Please input your password',
  'login.forgot_password?': 'Forgot password?',
  'login.login': 'Login',
  'login.create_new_account': 'Create new account',
  'login.dont_have_an_account': `Don't have an account?`,
  'login.sign_up_now': 'Sign up now',
  'login.explore_staking_event': 'and explore our Staking Rewards event.',

  // signup
  'signup.upload_id_title': 'Upload front and back image of your ID & your selfie',
  'signup.upload_license_title': 'Upload front and back image of your Driver License & your selfie',
  'signup.upload_passport_title': 'Upload image of your passport & your selfie',
  'signup.front': 'Front',
  'signup.back': 'Back',
  'signup.front_required': 'Please upload front image',
  'signup.back_required': 'Please upload back image',
  'signup.passport_required': 'Please upload passport image',
  'signup.selfie': 'Selfie',
  'signup.selfie_required': 'Please upload selfie image',
  'signup.verify': 'Verify',
  'signup.signup': 'Sign up',
  'signup.kyc': 'KYC',
  'signup.kyc_rejected': 'KYC is rejected, please try again',
  'signup.kyc_process': 'KYC is processing...',
  'signup.verify_again': 'Verify again',
  'signup.if_already_have_account': 'If you already have an account',
  'signup.login_here': 'Login here',

  'signup.email_required': 'Please input your email',
  'signup.email_invalid': 'The input is not valid E-mail',
  'signup.email': 'Email',
  'signup.strong_password_required':
    'Password must contain at least 8 characters with at least 1 uppercase letter, 1 numeric character and 1 special character',
  'signup.match_password_required': 'The two passwords that you entered do not match!',
  'signup.password_required': 'Please input your password',
  'signup.confirm_password_required': 'Please input your password again',
  'signup.password': 'Password',
  'signup.confirm_password': 'Confirm password',
  'signup.verify_title': 'A verification code has been sent to your email',
  'signup.verify_with_code': 'Enter the code',
  'signup.cannot_resend_otp': 'Cannot resend OTP, please try again',
  'signup.otp_required': 'Please input the otp to verify your email',
  'signup.next': 'Next',
  'signup.resend_code': 'Resend code',
  'signup.first_name': 'First name',
  'signup.last_name': 'Last name',
  'signup.first_name_required': 'Please input your first name',
  'signup.last_name_required': 'Please input your last name',
  'signup.address': 'Address',
  'signup.address_required': 'Please input your address',
  'signup.choose_paper': 'Choose one of three paper types below:',
  'signup.language': 'Language',
  'signup.resend_in': 'Resend in',
  'signup.dont_receive': 'Do not receive?',
  'signup.time_until_next_round': 'will open registration in',
  'signup.remaining_registration_time': 'Registration will close in',
  'signup.public_sale_end': 'Our public sale has ended',
  'signup.round_closed': 'registration has ended!',
  // wallet
  'wallet.add_wallet_message': 'Please add your wallet (BEP20) on BSC before buying BHO',
  'wallet.wallet_address': 'Wallet address',
  'wallet.check_your_wallet': 'Check your address',
  'wallet.add_wallet': 'Add wallet',
  'wallet.confirm_add_wallet': 'Confirm your wallet',
  'wallet.warning_one': 'We only accept wallet address (BEP20) from Trust Wallet, MetaMask Wallet.',
  'wallet.warning_two':
    'We do not accept payments from wallet addresses (BEP20) of the Centralized Exchanges (CEXs) like Binance, Huobi, Coinbase ...',
  'wallet.warning_three': 'Note: If you enter the wrong address, your BHO token will be lost.',
  'wallet.confirm_wallet': 'Are you sure this is your wallet address (BEP20)?',
  'wallet.guide': 'Instructions to get BEP20 wallet address on Trust Wallet/Metamask.',
  'wallet.guide_url': 'https://bholdus.github.io/bholdus-resources/Buy_Bholdus_[EN].pdf',

  // dashboard
  'dashboard.title': 'DASHBOARD',
  'dashboard.total_usd': 'USD',
  'dashboard.total_bho': 'BHO',
  'dashboard.id': 'ID',
  'dashboard.created_date': 'Date',
  'dashboard.amount_of_bho': 'Amount of BHO',
  'dashboard.you_pay': 'You paid',
  'dashboard.release_status': 'BHO Release progress',
  'dashboard.status': 'Status',
  'dashboard.action': 'Action',
  'dashboard.view_detail': 'View detail',
  'dashboard.confirm': 'Confirm',
  'dashboard.no_transaction': "You don't have any transaction. Click",
  'dashboard.buy_bho': 'Buy BHO',
  'dashboard.to_buy_bho': 'To buy BHO',
  'dashboard.now': 'now',
  'dashboard.imo_message': 'For more information on the IMO round, ',
  'dashboard.imo_click_here': 'click here.',
  'dashboard.delay_message':
    'We will create your transaction as soon as we receive your payment. {br}The process may be delayed for a while. Please come back and check your transaction later on Dashboard.',
  'create_request.final_action_text': `After successfully transferring money to the Bholdus's wallet address above, press`,
  'dashboard.empty_transaction_warning_1':
    'Investors will receive a confirmation email upon successful purchase of IMO, and information about tokens and smart contracts will appear on your Dashboard at',
  'dashboard.empty_transaction_warning_2': `In the event that the number of BHO tokens of the IMO round has been sold out but investors still deposit money into BHO's wallet, Bholdus will refund this amount within 24 - 48 hours or faster.`,
  'dashboard.empty_transaction_warning_3':
    'Investors should only deposit money to BHO Wallet address according to the information provided when using the Buy BHO order at',
  'dashboard.empty_transaction_warning_4': `Bholdus will not be held responsible if you deposit funds to addresses other than Bholdus wallet. You cannot deposit funds to BHO contracts or token addresses either.`,

  // forgot password
  'forgot_password.title': 'RESET PASSWORD',
  'forgot_password.cannot_resend_otp': 'Cannot resend OTP, please try again',
  'forgot_password.email_required': 'Please input your email',
  'forgot_password.email': 'Email',
  'forgot_password.send_mail': 'Send verify email',
  'forgot_password.strong_password_required':
    'Password must contain at least 8 characters with at least 1 uppercase letter, 1 numeric character and 1 special character',
  'forgot_password.match_password_required': 'The two passwords that you entered do not match!',
  'forgot_password.password_required': 'Please input your password',
  'forgot_password.confirm_password_required': 'Please input your password again',
  'forgot_password.password': 'Password',
  'forgot_password.confirm_password': 'Confirm password',
  'forgot_password.verify_with_email': 'Verify with the code in your email',
  'forgot_password.otp_required': 'Please input the otp to verify your email',
  'forgot_password.reset_password': 'Reset password',
  'forgot_password.resend_in': 'Resend in',
  'forgot_password.resend_code': 'Resend code',

  // change password page
  'change_password.title': 'CHANGE PASSWORD',
  'change_password.current_password': 'Current password',
  'change_password.current_password_required': 'Please input your current password',
  'change_password.new_password': 'New password',
  'change_password.new_password_required': 'Please input your new password',
  'change_password.strong_password_required':
    'Password must contain at least 8 characters with at least 1 uppercase letter,  1 numeric character and 1 special character',
  'change_password.match_password_required': 'The two passwords that you entered do not match!',
  'change_password.confirm_new_password': 'Confirm new password',
  'change_password.confirm_new_password_required': 'Please input your new password again',
  'change_password.change_password': 'Change password',

  // create request
  'create_request.title': 'Buy BHO',
  'create_request.method': 'Method',
  'create_request.choose_method': 'Choose method',
  'create_request.you_pay': 'You pay (USD)',
  'create_request.you_buy': 'You buy (BHO)',
  'create_request.buy_amount_required': 'Please input the buying amount',
  'create_request.pay_amount_required': 'Please input the paying amount',
  'create_request.cannot_buy_more_than': 'Cannot buy more than',
  'create_request.bho_a_time': 'BHO a time',
  'create_request.usd_a_time': 'USD a time',
  'create_request.cannot_buy_less_than': 'Cannot buy less than',
  'create_request.continues': 'Continue',
  'create_request.inlude_rate': 'This amount includes Rate',
  'create_request.bep20_wallet': 'BSC Binance Smart Chain’s Wallet Address',
  'create_request.wallet_address': 'Wallet Address',
  'create_request.account_number': 'Account Number',
  'create_request.standard': 'Standard',
  'create_request.additional_info': 'Additional Information',
  'create_request.cancel': 'Cancel',
  'create_request.confirm_request': 'Confirm request',
  'create_request.your_wallet_address': 'Your wallet address',
  'create_request.your_wallet_address_note':
    '(The wallet address that you will use to send money and receive BHO tokens)',
  'create_request.your_trust_wallet_address': 'Your TRUST wallet address',
  'create_request.your_trust_wallet_address_note':
    '(The TRUST wallet address that you will use to send money and receive BHO tokens)',
  'create_request.our_wallet_address': 'Bholdus wallet address',
  'create_request.our_wallet_address_note': '(Must deposit your money to this wallet address only)',
  'create_request.warning': 'Warning',
  'create_request.warning_method': 'Please choose the method',
  'create_request.warning_one': '1. Please send fund to the exact address above.',
  'create_request.warning_two': '2. If you send to the wrong address, you will lost it.',
  'create_request.warning_three':
    '3. Be sure that you send fund from your address above. It is also the address that will receive BHO later.',
  'create_request.min': 'Min',
  'create_request.max': 'Max',
  'create_request.manually': 'Manually',
  'create_request.refund': `In the event that the number of BHO tokens of the IMO round has been sold out but investors still deposit money into BHO's Wallet, Bholdus will refund this amount within 24h-48h or faster.`,

  // request detail
  'request_detail.transaction': 'TRANSACTION',
  'request_detail.method': 'Method',
  'request_detail.choose_method': 'Choose method',
  'request_detail.you_pay': 'You paid (USD)',
  'request_detail.you_buy': 'You bought (BHO)',
  'request_detail.inlude_rate': 'This amount includes Rate',
  'request_detail.bep20_wallet': 'BSC Binance Smart Chain’s Wallet Address',
  'request_detail.wallet_address': 'Wallet Address',
  'request_detail.account_number': 'Account Number',
  'request_detail.standard': 'Standard',
  'request_detail.additional_info': 'Additional Information',
  'request_detail.cancel': 'Cancel',
  'request_detail.confirm_transaction': 'Confirm request',
  'request_detail.i_confirm': 'I confirm that I sent',
  'request_detail.from_this_wallet': 'from this wallet',
  'request_detail.confirm_transaction_by': 'Please confirm your transaction request by',
  'request_detail.input_transaction_id': 'Input your transaction ID',
  'request_detail.upload_payment_slip': 'Upload payment slip',
  'request_detail.transaction_id': 'Transaction ID',
  'request_detail.payment_slip': 'Payment slip',
  'request_detail.cancel_request': 'Cancel request',
  'request_detail.back_to_dashboard': 'Back to Dashboard',
  'request_detail.status': 'Status',
  'request_detail.your_wallet_address': 'Your wallet address',
  'request_detail.contract': 'Contract',
  'request_detail.timeline': 'Timeline',
  'request_detail.information': 'Information',
  'request_detail.lock_status': 'Status',
  'request_detail.release': 'Release',
  'request_detail.pending': 'Pending',
  'request_detail.released': 'Released',

  // Referral
  'referral.id': '#',
  'referral.title': 'REFERRAL',
  'referral.referral_link': 'Your referral link:',
  'referral.successful_referrals': 'Successful referrals',
  'referral.total_usd': 'Total USD',
  'referral.total_bho': 'Total BHO',
  'referral.time': 'Time',
  'referral.transaction': 'Transaction',
  'referral.level': 'Level',
  'referral.commission_usd': 'Commission (USD)',
  'referral.commission_bho': 'Commission (BHO)',
  'referral.no_referral': 'You do not have any referral. Share your referral link now.',
  'referral.commission_note':
    'The commission is converted to BHO at public sales price (0.005 USD) and tokens will be sent to your wallet on October 2nd, 2021',
  'referral.commission_info_message': 'You will receive 2F Bonus when supporting other investors to join the project.',
  'referral.commisision_tier': 'F',
  'thankyou.info_1': 'Your account is activated!',
  'thankyou.info_2': 'We will now redirect you to the dashboard',
  // Countdown
  'countdown.days': 'Days',
  'countdown.hours': 'Hours',
  'countdown.mins': 'Mins',
  'countdown.secs': 'Secs',
  // sale
  'sale.total_bho_sale': 'Total BHO Sale',
  'sale.time_until_next_round': 'Next round will open in',
  'sale.time_until_round': 'This round will open for sale in',
  'sale.remaining_buy_time': 'This round will close in',
  'sale.public_sale_end': 'Our public sale has ended',
  'sale.sold_out': 'Sold out!',
  // sale statistic
  'statistic.sell_all': 'All BHO token for this round have been sold! Please comeback in next round!',
  'statistic.buy_all': 'You have bought the max amount of BHO! Please comeback in next round',
  // transaction status
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  pending: 'Pending',
  in_progress: 'In progress',
  completed: 'Completed',
  accepted: 'Accepted',
  // network
  'network.error': 'The number of connections is too many at this time. Please try again in 30 seconds.',

  // staking_detail
  'staking_detail.pending': 'Staking',
  'staking_detail.released': 'Paid',
  'staking_detail.amount': 'Amount',
  'staking_detail.transaction_id': 'Transaction ID',
  'staking_detail.staking_amount': 'Staking amount',
  'staking_detail.back': 'Back',
  'staking_detail.end_date': 'End date',

  // create staking
  'create_staking.rewards': 'Rewards',
  'create_staking.amount': 'Amount',
  'create_staking.value_locked': 'Total staked',
  'create_staking.min_amount': 'Min amount',
  'create_staking.cannot_stake_less_than': 'Cannot stake less than',
  'create_staking.bho_a_time': 'BHO a time',
  'create_staking.your_wallet_address': 'Your wallet address',
  'create_staking.your_wallet_address_note': '(The wallet address that you will use to send BHO tokens)',
  'create_staking.your_trust_wallet_address': 'Your TRUST wallet address',
  'create_staking.your_trust_wallet_address_note': '(The TRUST wallet address that you will use to send BHO tokens)',
  'create_staking.our_wallet_address': 'Bholdus wallet address',
  'create_staking.our_wallet_address_staking_program': 'staking program',
  'create_staking.our_wallet_address_note': '(Must deposit your BHO to this wallet address only)',
  'create_staking.warning': 'Warning',
  'create_staking.final_action_text': `After successfully transferring BHO to the Bholdus's wallet address above, press`,
  'create_staking.confirm_request': 'Confirm request',
  'create_staking.cancel': 'Cancel',
  'create_staking.deposit': 'Deposit',
  'create_staking.amount_required': 'Amount required',
  'create_staking.total_reward': 'Total rewards',
  'create_staking.back': 'Back',
  'create_staking.program_list': 'Programs list',
  'create_staking.program': 'Program',
  'create_staking.current_staking': 'Current BHO stakings',
  'create_staking.capital_return_after_90_day': 'Principle paid after 90 days',
  'create_staking.interest_pay_after_90_day': 'Interest paid after 90 days',
  'create_staking.interest_pay_after_every_30_day': 'Interest paid every 30 days',
  'create_staking.make_payment': 'Make payment',
  'create_staking.make_payment_content':
    'Proceed to make payment to our wallet. Make sure you make payment using use one of these wallets:',
  'create_staking.delay_message':
    'We will create your staking as soon as we receive your payment. {br}The process may be delayed for a while. Please come back and check it later.',
  'create_staking.changed_message': 'The new staking programs are now available at {br}',
  // staking round info
  'staking.time_until_round': 'Staking Rewards event will open in',
  'staking.remaining_stake_time': 'Staking Rewards event will end in',
  'staking.staking_end': 'Our Staking Rewards event has ended',
  'staking.guide': 'What is our Staking Rewards event?',

  // term
  apy: 'Lorem ipsum',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'en',
  antdData,
  messages,
};
