export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const OTHER_CONFIG = 'OTHER_CONFIG';

export const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';

export const SITE_ADDRESS = process.env.REACT_APP_SITE_ADDRESS;

export const BSC_SCAN_URL = process.env.REACT_APP_BSC_SCAN_URL;
