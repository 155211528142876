import actions from './actions';

const initialState = {
  id: '',
  username: '',
  account_status: '',
  kyc_status: '',
  created_at: '',
  updated_at: '',
  referral: null,
  authorized: false,
  loading: false,
  kyc: null,
  documents: null,
  wallet_addresses: [],
  locale: 'en',
  jwt_token: '',
  infobip_jwt: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET:
      return initialState;
    default:
      return state;
  }
}

export const KCYStatusSelector = (state) => state.user.kyc_status;

export const accountStatusSelector = (state) => state.user.account_status;

export const userSelector = (state) => state.user;
export const getCurrentLocale = (state) => state.user.locale;
