/**
 *
 * NavButton
 *
 */
import classNames from 'classnames';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

export const NavButton = memo((props) => {
  const { routeInfo, active = false } = props;
  const { url, label, icon } = routeInfo;

  return (
    <Link to={url}>
      <div className={classNames([styles.nav_button_wrapper, active ? styles.nav_button_wrapper_active : ''])}>
        <div className={styles.button_inside_wrapper}>
          <img src={icon} alt={label} />
          <span className={styles.button_label}>{label}</span>
        </div>
      </div>
    </Link>
  );
});
