import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';

const POLLING_INTERVAL = 12000;
export const walletconnect = new WalletConnectConnector({
  rpc: {
    // 56: "https://bsc-dataseed1.binance.org",
    // 1: "https://mainnet.mycustomnode.com",
    // 3: "https://ropsten.mycustomnode.com",
    // 4: "https://rinkeby.infura.io/v3/ce3c8fb9c024463fa53477a2c94ab1ec",
    56: 'https://bsc-dataseed1.binance.org',
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: ['trust'],
  },
  pollingInterval: POLLING_INTERVAL,
});

export const injectedconnector = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 56, 97],
});
