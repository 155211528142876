/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { UserOutlined, LogoutOutlined, WalletOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import actions from 'redux/user/actions';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
import styles from './style.module.scss';

class ProfileMenu extends React.Component {
  logout = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch({
      type: actions.LOGOUT,
    });
  };

  render() {
    const { user } = this.props;
    const menu = (
      <Menu
        selectable={false}
        style={{
          backgroundColor: '#1FC7D4',
          color: 'white',
          marginTop: '5px',
        }}
      >
        <Menu.Item key='hello'>
          <Link to={`${BASE_URL}/dashboard`} style={{ color: 'white' }}>
            <FormattedMessage id='profile_menu.hello' />, &nbsp;
            {lodash.isEmpty(user.kyc)
              ? user.username
              : `${lodash.get(user, 'kyc.first_name')} ${lodash.get(user, 'kyc.last_name')}`}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        {user.account_status === 'active' && (
          <Menu.Item key='wallet'>
            <Link to={`${BASE_URL}/wallet`}>
              <WalletOutlined style={{ color: 'white' }} />
              &nbsp;
              <span style={{ color: 'white' }}>
                <FormattedMessage id='profile_menu.wallet' />
              </span>
            </Link>
          </Menu.Item>
        )}
        {user.account_status !== 'unverified' && (
          <Menu.Item key='change-pass'>
            <Link to={`${BASE_URL}/change-password`}>
              <UserOutlined style={{ color: 'white' }} />
              &nbsp;
              <span style={{ color: 'white' }}>
                <FormattedMessage id='profile_menu.change_password' />
              </span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key='logout'>
          <div onClick={this.logout}>
            <LogoutOutlined style={{ color: 'white' }} />
            &nbsp;
            <span style={{ color: 'white' }}>
              <FormattedMessage id='profile_menu.logout' />
            </span>
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={() => {}}>
        <div className={styles.dropdown}>
          {lodash.isEmpty(user.kyc) ? (
            <Avatar>{lodash.get(user, 'username').charAt(0).toUpperCase()}</Avatar>
          ) : (
            <Avatar>{`${lodash.get(user, 'kyc.first_name').charAt(0).toUpperCase()}${lodash
              .get(user, 'kyc.last_name[0]')
              .charAt(0)
              .toUpperCase()}`}</Avatar>
          )}
          <Image src='/images/menu-rectangle.png' alt='menu' />
        </div>
      </Dropdown>
    );
  }
}

export default connect()(ProfileMenu);
