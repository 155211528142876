import ReactGA from 'react-ga4';
import { BASE_URL } from 'configs';

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const trackingId = process.env.REACT_APP_GA || '';
  // console.log('isDev', isDev, trackingId);
  if (trackingId) ReactGA.initialize(trackingId, { testMode: isDev });
};

const sendEvent = (payload) => {
  const category = BASE_URL !== '' ? 'imo_bholdus_com' : 'sale_bholdus_com';
  const finalPayload = { category, ...payload };
  // console.log('sendEvent', final_payload);
  ReactGA.event(finalPayload);
};

const sendPageview = (path) => {
  ReactGA.set({ hitType: 'pageview', page: `${BASE_URL}${path}` });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  sendEvent,
  sendPageview,
};
