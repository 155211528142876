export const analyseEmail = (email) => {
  // const name   = email.substring(0, email.lastIndexOf("@"));
  const domain = email.substring(email.lastIndexOf('@') + 1);
  const blacklist = [
    /tmp/,
    /free/,
    /.tk/,
    /laste/,
    /firste/,
    /supere/,
    /dropmail/,
    /emlpro/,
    /emlhub/,
    /yomail/,
    /10mail/,
    /zeroe/,
    /freeml/,
    /10mail/,
    /mailinator/,
    /emailfake/,
    /email-fake/,
    /temp-mail/,
    /fakemail/,
    /emkei/,
    /valimail/,
    /tempmail/,
  ];
  const isMatch = blacklist.some((rx) => rx.test(domain));
  return !isMatch;
};
