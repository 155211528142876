/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Spin, Space } from 'antd';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styles from './style.module.scss';

// {
//   "onchain_id": 1, // staking ID
//   "amount": "10000", // BHO, initial deposit amount
//   "program_id": 1,
//   "apy": 5066, // 50.66%
//   "interest_date": 1640995200, // timestamp in seconds
//   "state": "staked" || "unstaked" || "admin_unstaked",
//   "start_date": datetime,
//   "user": 1, // staker id,
//   "user_wallet": "0x123", // staker wallet
// }

const StakeItem = (props) => {
  const { stake, onClick, noHover } = props;

  if (!stake) {
    return (
      <Space size='middle'>
        <Spin size='large' />
      </Space>
    );
  }
  const { amount, apy, reward, created_at_onchain } = stake;
  return (
    <div className={[styles.stake_item_wrapper, noHover ? styles.no_hover : ''].join(' ')} onClick={onClick}>
      <div
        className={styles.flex_row}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '80%',
          flex: 1,
        }}
      >
        {!isMobile && <img src='/images/bho_token.svg' alt='bho' />}

        <div
          className={styles.flex_col}
          style={{
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            marginLeft: 20,
            lineHeight: '100%',
            height: '100%',
          }}
        >
          <div>
            <FormattedMessage id='staking_detail.end_date' />
          </div>
          <div
            style={{
              fontWeight: '600',
              fontSize: '16px',
            }}
          >
            {dayjs(created_at_onchain).add(90, 'day').format('DD/MM/YYYY')}
          </div>
        </div>
      </div>
      <div
        className={styles.flex_col}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '80%',
          flex: 1,
        }}
      >
        <div>
          <div
            className={styles.flex_row}
            style={{
              alignItems: 'baseline',
            }}
          >
            {apy}% APY
          </div>
          <div
            style={{
              fontWeight: '600',
              fontSize: '16px',
            }}
          >
            <FormattedNumber value={Math.floor(reward)} />
          </div>
        </div>
      </div>
      <div
        className={styles.flex_col}
        style={{
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '80%',
          flex: 1,
        }}
      >
        <div>
          <div>
            <FormattedMessage id='staking_detail.staking_amount' />
          </div>
          <div
            style={{
              fontWeight: '600',
              fontSize: '16px',
            }}
          >
            <FormattedNumber value={Math.floor(amount)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeItem;
