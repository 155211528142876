import { notification } from 'antd';
import lodash from 'lodash';
import { store } from '../../store';

import enTranslations from './messages/en.json';
import viTranslations from './messages/vi.json';

export function showSuccess(message) {
  const state = store.getState();
  const locale = lodash.get(state, 'user.locale');
  const translations = locale === 'vi' ? viTranslations : enTranslations;
  notification.success({
    message: translations.Success || 'Success',
    description: translations[message] || message,
  });
}

export function showInfo(message) {
  const state = store.getState();
  const locale = lodash.get(state, 'user.locale');
  const translations = locale === 'vi' ? viTranslations : enTranslations;
  notification.info({
    message: translations.Info || 'Notification',
    description: translations[message] || message,
  });
}

export function showError(message) {
  const state = store.getState();
  const locale = lodash.get(state, 'user.locale');
  const translations = locale === 'vi' ? viTranslations : enTranslations;
  notification.error({
    message: translations.Error || 'Error',
    description: translations[message] || message,
  });
}

export function showMessageWithTemplate(message, data) {
  const state = store.getState();
  const locale = lodash.get(state, 'user.locale');
  const translations = locale === 'vi' ? viTranslations : enTranslations;
  const descriptionTemplate = translations[message] || message;
  const description = lodash.template(descriptionTemplate)(data);
  notification.info({
    message: translations.Info || 'Notification',
    description,
  });
}
