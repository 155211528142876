import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

const RATE_DOMAIN = 'rates';

const TRANSACTION_DOMAIN = 'transactions';

export async function getList({ offset = 0, limit = 20 }) {
  const url = `${BASE_API_URL}/${TRANSACTION_DOMAIN}?_start=${offset}&_limit=${limit}`;
  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getDetail(id) {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}/${id}`,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function createTransaction(transaction) {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}`,
    method: 'post',
    bodyData: {
      ...transaction,
    },
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function requestTransaction(transaction) {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}/request`,
    method: 'post',
    bodyData: {
      ...transaction,
    },
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function cancelTransaction(id) {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}/${id}/cancel`,
    method: 'post',
    bodyData: {},
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function confirmPayment(id, payment) {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}/${id}/confirm`,
    method: 'post',
    bodyData: {
      payment,
    },
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getRate(currency) {
  return requestAPI({
    url: `${BASE_API_URL}/${RATE_DOMAIN}/${currency}`,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}

export async function getCurrentTotal() {
  return requestAPI({
    url: `${BASE_API_URL}/${TRANSACTION_DOMAIN}/total/current`,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
