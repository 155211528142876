import antdData from 'antd/lib/locale/vi_VN';

const messages = {
  'guide.url': 'https://bholdus.github.io/bholdus-resources/Buy_Bholdus_[VN].pdf',
  'guide.text': 'Hướng dẫn mua BHO',
  'document_type.national_id': 'CMND/CCCD',
  'document_type.driver_license': 'Bằng lái',
  'document_type.passport': 'Hộ chiếu',

  // navbar
  'navbar.dashboard': 'Dashboard',
  'navbar.referral': 'Referral',
  'navbar.staking': 'Staking',

  // button
  'button.copy': 'Sao chép',

  // profile menu
  'profile_menu.hello': 'Xin chào',
  'profile_menu.wallet': 'Ví',
  'profile_menu.change_password': 'Đổi mật khẩu',
  'profile_menu.logout': 'Đăng xuất',

  // login
  'login.title': 'ĐĂNG NHẬP',
  'login.email': 'Email',
  'login.email_required': 'Xin hãy nhập email',
  'login.email_invalid': 'Email không hợp lệ',
  'login.password': 'Mật khẩu',
  'login.password_required': 'Xin hãy nhập mật khẩu',
  'login.forgot_password?': 'Quên mật khẩu?',
  'login.login': 'Đăng nhập',
  'login.create_new_account': 'Tạo tài khoản mới',
  'login.dont_have_an_account': `Chưa có tài khoản?`,
  'login.sign_up_now': 'Đăng ký ngay',
  'login.explore_staking_event': 'và khám phá Sự kiện Staking Rewards của chúng tôi.',

  // signup
  'signup.upload_id_title': 'Tải lên mặt trước và mặt sau CMND/CCCD & ảnh selfie',
  'signup.upload_license_title': 'Tải lên mặt trước và mặt sau bằng lái & ảnh selfie',
  'signup.upload_passport_title': 'Tải lên ảnh hộ chiếu & ảnh selfie',
  'signup.front': 'Mặt trước',
  'signup.back': 'Mặt sau',
  'signup.front_required': 'Tải lên mặt trước',
  'signup.back_required': 'Tải lên mặt sau',
  'signup.passport_required': 'Tải lên ảnh hộ chiếu',
  'signup.selfie': 'Ảnh selfie',
  'signup.selfie_required': 'Tải lên ảnh selfie',
  'signup.verify': 'Xác nhận',
  'signup.signup': 'Đăng ký',
  'signup.kyc': 'KYC',
  'signup.kyc_rejected': 'Danh tính của bạn chưa được chấp nhận, xin hãy thử lại',
  'signup.kyc_process': 'Danh tính của bạn đang được kiểm tra...',
  'signup.verify_again': 'Xác nhận lại',
  'signup.if_already_have_account': 'Nếu bạn đã có tài toàn khoản',
  'signup.login_here': 'Đăng nhập tại đây',

  'signup.email_required': 'Xin hãy nhập email',
  'signup.email_invalid': 'Email không hợp lệ',
  'signup.email': 'Email',
  'signup.strong_password_required':
    'Mật khẩu phải có ít nhất 8 kí tự với ít nhất 1 kí tự hoa, 1 chữ số và 1 kí tự đặc biệt',
  'signup.match_password_required': 'Hai mật khẩu không giống nhau',
  'signup.password_required': 'Hãy nhập mật khẩu',
  'signup.confirm_password_required': 'Hãy nhập lại mật khẩu',
  'signup.password': 'Mật khẩu',
  'signup.confirm_password': 'Nhập lại mật khẩu',
  'signup.verify_title': 'Một mã xác thực đã được gửi tới email của bạn',
  'signup.verify_with_code': 'Xin nhập mã',
  'signup.cannot_resend_otp': 'Không thể gửi lại OTP, xin thử lại lần nữa',
  'signup.otp_required': 'Hãy nhập mã OTP',
  'signup.next': 'Tiếp tục',
  'signup.resend_code': 'Gửi lại mã',
  'signup.first_name': 'Tên',
  'signup.last_name': 'Họ',
  'signup.first_name_required': 'Xin hãy nhập tên',
  'signup.last_name_required': 'Xin hãy nhập họ',
  'signup.address': 'Địa chỉ',
  'signup.address_required': 'Xin hãy nhập địa chỉ',
  'signup.choose_paper': 'Chọn một trong ba loại giấy tờ:',
  'signup.language': 'Ngôn ngữ',
  'signup.resend_in': 'Gửi lại trong',
  'signup.dont_receive': 'Bạn không nhận được mã?',
  'signup.time_until_next_round': 'sẽ mở đăng ký trong',
  'signup.remaining_registration_time': 'Thời gian đăng ký sẽ đóng trong',
  'signup.public_sale_end': 'Đợt bán công khai đã kết thúc',
  'signup.round_closed': 'đã đóng đăng ký!',
  // wallet
  'wallet.add_wallet_message': 'Xin hãy nhập địa chỉ ví (BEP20) của bạn trên mạng BSC trước khi thực hiện giao dịch.',
  'wallet.wallet_address': 'Địa chỉ ví',
  'wallet.check_your_wallet': 'Kiểm tra địa chỉ',
  'wallet.add_wallet': 'Thêm ví',
  'wallet.confirm_add_wallet': 'Xác nhận thông tin ví',
  'wallet.warning_one': 'Chúng tôi chỉ chấp nhận các địa chỉ (BEP20) từ các ví Trust Wallet, MetaMask Wallet.',
  'wallet.warning_two':
    'Chúng tôi không chấp nhận các địa chỉ từ ví BEP20 của các sàn giao dich như Binance, Huobi, Coinbase ...',
  'wallet.warning_three': 'Lưu ý: Nếu bạn nhập sai địa chỉ, bạn sẽ không thể nhận được đồng BHO.',
  'wallet.confirm_wallet': 'Bạn có chắc chắn đây là địa chỉ ví BEP20 của bạn không?',
  'wallet.guide': 'Hướng dẫn lấy địa chỉ ví BEP20 trên Trust Wallet/Metamask.',
  'wallet.guide_url': 'https://bholdus.github.io/bholdus-resources/Buy_Bholdus_[VN].pdf',
  // dashboard
  'dashboard.title': 'DASHBOARD',
  'dashboard.total_usd': 'USD',
  'dashboard.total_bho': 'BHO',
  'dashboard.id': 'ID',
  'dashboard.created_date': 'Ngày tạo',
  'dashboard.amount_of_bho': 'Số lượng BHO',
  'dashboard.you_pay': 'Bạn trả',
  'dashboard.release_status': 'BHO đã chuyển',
  'dashboard.status': 'Trạng thái',
  'dashboard.action': 'Tuỳ chọn',
  'dashboard.view_detail': 'Chi tiết',
  'dashboard.confirm': 'Xác nhận',
  'dashboard.no_transaction': 'Bạn không có giao dịch nào. Nhấn',
  'dashboard.buy_bho': 'Mua BHO',
  'dashboard.to_buy_bho': 'Để mua BHO',
  'dashboard.now': 'ngay',
  'dashboard.imo_message': 'Vui lòng xem thông tin về vòng mở bán IMO, ',
  'dashboard.imo_click_here': 'nhấn vào đây.',
  'dashboard.delay_message':
    'Chúng tôi sẽ tạo giao dịch ngay khi nhận được thanh toán của bạn. {br}Quy trình khởi tạo có thể bị chậm ít phút. Xin hãy quay lại sau và kiểm tra giao dịch ở Dashboard.',
  'dashboard.empty_transaction_warning_1':
    'Nhà đầu tư sẽ nhận được email xác nhận khi mua thành công IMO và thông tin về token và smart contract sẽ hiện trên Dashboard tại trang',
  'dashboard.empty_transaction_warning_2':
    'Trong trường hợp số BHO token của vòng IMO đã được bán hết nhưng nhà đầu tư vẫn gửi tiền vào Ví của BHO thì Bholdus sẽ hoàn lại khoản tiền này trong vòng 24h-48h hoặc nhanh hơn.',
  'dashboard.empty_transaction_warning_3':
    'Nhà đầu tư chỉ gửi tiền vào địa chỉ Ví BHO theo thông tin được cung cấp khi sử dụng lệnh Mua BHO tại trang',
  'dashboard.empty_transaction_warning_4':
    'Bholdus sẽ không chịu trách nhiệm khi nhà đầu tư gửi tiền vào các địa chỉ không phải là Ví của Bholdus. (Bao gồm cả các địa chỉ contract hay token của BHO, nhà đầu tư không được gửi tiền vào các địa chỉ này).',

  // forgot password
  'forgot_password.title': 'ĐẶT LẠI MẶT KHẨU',
  'forgot_password.cannot_resend_otp': 'Không thể gửi lại OTP, xin thử lại lần nữa',
  'forgot_password.email_required': 'Xin nhập email',
  'forgot_password.email': 'Email',
  'forgot_password.send_mail': 'Gửi email xác nhận',
  'forgot_password.strong_password_required':
    'Mật khẩu phải có ít nhất 8 kí tự với ít nhất 1 kí tự hoa, 1 chữ số và 1 kí tự đặc biệt',
  'forgot_password.match_password_required': 'Hai mật khẩu không giống nhau',
  'forgot_password.password_required': 'Xin hãy nhập mật khẩu',
  'forgot_password.confirm_password_required': 'Xin hãy nhập lại mật khẩu',
  'forgot_password.password': 'Mật khẩu',
  'forgot_password.confirm_password': 'Nhập lại mật khẩu',
  'forgot_password.verify_with_email': 'Xác nhận với OTP',
  'forgot_password.otp_required': 'Xin nhập OTP để xác nhận',
  'forgot_password.reset_password': 'Đặt lại mật khẩu',
  'forgot_password.resend_in': 'Gửi lại trong',
  'forgot_password.resend_code': 'Gửi lại mã',

  // change password page
  'change_password.title': 'ĐỔI MẬT KHẨU',
  'change_password.current_password': 'Mật khẩu hiện tại',
  'change_password.current_password_required': 'Xin hãy nhập mật khẩu hiện tại',
  'change_password.new_password': 'Mật khẩu mới',
  'change_password.new_password_required': 'Xin hãy nhập mật khẩu mới',
  'change_password.strong_password_required':
    'Mật khẩu phải có ít nhất 8 kí tự với ít nhất 1 kí tự hoa, 1 chữ số và 1 kí tự đặc biệt',
  'change_password.match_password_required': 'Hai mật khẩu không giống nhau',
  'change_password.confirm_new_password': 'Nhập lại mật khẩu mới',
  'change_password.confirm_new_password_required': 'Xin hãy nhập lại mật khẩu mới',
  'change_password.change_password': 'Đổi mật khẩu',

  // create request
  'create_request.title': 'Mua BHO',
  'create_request.method': 'Phương thức',
  'create_request.choose_method': 'Chọn phương thức',
  'create_request.you_pay': 'Bạn trả (USD)',
  'create_request.you_buy': 'Bạn mua (BHO)',
  'create_request.buy_amount_required': 'Xin hãy nhập vào số lượng mua',
  'create_request.pay_amount_required': 'Xin hãy nhập vào số lượng',
  'create_request.cannot_buy_more_than': 'Không thể mua nhiều hơn',
  'create_request.bho_a_time': 'BHO 1 lần',
  'create_request.usd_a_time': 'USD 1 lần',
  'create_request.cannot_buy_less_than': 'Không thể mua ít hơn',
  'create_request.continues': 'Tiếp tục',
  'create_request.inlude_rate': 'Số lượng trên dựa vào tỉ giá',
  'create_request.bep20_wallet': 'Địa chỉ ví trên BSC Binance Smart Chain',
  'create_request.wallet_address': 'Địa chỉ ví',
  'create_request.account_number': 'Số tài khoản',
  'create_request.standard': 'Tiêu chuẩn',
  'create_request.additional_info': 'Thông tin khác',
  'create_request.cancel': 'Huỷ',
  'create_request.confirm_request': 'Xác nhận giao dịch',
  'create_request.your_wallet_address': 'Địa chỉ ví của bạn',
  'create_request.your_trust_wallet_address': 'Địa chỉ ví TRUST của bạn',
  'create_request.your_wallet_address_note': '(Địa chỉ ví mà bạn sẽ dùng để gửi tiền đi và nhận token BHO về)',
  'create_request.your_trust_wallet_address_note':
    '(Địa chỉ ví TRUST mà bạn sẽ dùng để gửi tiền đi và nhận token BHO về)',
  'create_request.our_wallet_address': 'Địa chỉ ví của Bholdus',
  'create_request.our_wallet_address_note': '(Chỉ gửi tiền vào đúng địa chỉ này)',
  'create_request.warning_method': 'Hãy chọn phương thức thanh toán',
  'create_request.warning': 'Lưu ý',
  'create_request.warning_one': '1. Xin hãy gửi tiền đến đúng địa chỉ ví trên.',
  'create_request.warning_two': '2. Nếu gửi sai địa chỉ, bạn sẽ mất toàn bộ số tiền.',
  'create_request.warning_three':
    '3. Hãy chắc rằng bạn gửi tiền từ địa chỉ ví trên. Nó cũng chính là địa chỉ ví sẽ nhận BHO sau này.',
  'create_request.min': 'Tối thiểu',
  'create_request.max': 'Tối đa',
  'create_request.manually': 'Tự chuyển',
  'create_request.refund':
    'Trong trường hợp số BHO token của vòng IMO đã được bán hết nhưng nhà đầu tư vẫn gửi tiền vào Ví của BHO thì Bholdus sẽ hoàn lại khoản tiền này trong vòng 24h-48h hoặc nhanh hơn.',
  'create_request.final_action_text': 'Sau khi chuyển tiền thành công vào ví Bholdus ở trên, bấm',

  // request detail
  'request_detail.transaction': 'GIAO DỊCH',
  'request_detail.method': 'Phương thức',
  'request_detail.choose_method': 'Chọn phương thức',
  'request_detail.you_pay': 'Bạn đã trả (USD)',
  'request_detail.you_buy': 'Bạn đã mua (BHO)',
  'request_detail.inlude_rate': 'Số lượng trên dựa vào tỉ giá',
  'request_detail.bep20_wallet': 'Địa chỉ ví trên BSC Binance Smart Chain',
  'request_detail.wallet_address': 'Địa chỉ ví',
  'request_detail.account_number': 'Số tài khoản',
  'request_detail.standard': 'Tiêu chuẩn',
  'request_detail.additional_info': 'Thông tin khác',
  'request_detail.cancel': 'Huỷ',
  'request_detail.confirm_transaction': 'Xác nhận giao dịch',
  'request_detail.i_confirm': 'Tôi xác nhận đã chuyển',
  'request_detail.from_this_wallet': 'từ địa chỉ ví',
  'request_detail.confirm_transaction_by': 'Xin hãy xác nhận giao dịch bằng cách',
  'request_detail.input_transaction_id': 'Nhập vào mã giao dịch',
  'request_detail.upload_payment_slip': 'Tải lên hoá đơn chuyển tiền',
  'request_detail.transaction_id': 'Mã giao dịch',
  'request_detail.payment_slip': 'Hoá đơn chuyển tiền',
  'request_detail.cancel_request': 'Huỷ',
  'request_detail.back_to_dashboard': 'Trở lại trang chủ',
  'request_detail.status': 'Trạng thái',
  'request_detail.your_wallet_address': 'Địa chỉ ví của bạn',
  'request_detail.contract': 'Hợp đồng thông minh',
  'request_detail.timeline': 'Thời gian',
  'request_detail.information': 'Thông tin',
  'request_detail.lock_status': 'Trạng thái',
  'request_detail.release': 'Mở khoá',
  'request_detail.pending': 'Đang chờ',
  'request_detail.released': 'Đã mở khoá',

  // Referral
  'referral.id': '#',
  'referral.title': 'GIỚI THIỆU',
  'referral.referral_link': 'Đường dẫn giới thiệu',
  'referral.successful_referrals': 'Giới thiệu',
  'referral.total_usd': 'Tổng USD',
  'referral.total_bho': 'Tổng BHO',
  'referral.time': 'Thời gian',
  'referral.transaction': 'Giao dịch',
  'referral.level': 'Cấp độ',
  'referral.commission_usd': 'Hoa hồng (USD)',
  'referral.commission_bho': 'Hoa hồng (BHO)',
  'referral.no_referral': 'Chia sẻ link giới thiệu bên trên để nhận ngay hoa hồng.',
  'referral.commission_note':
    'Toàn bộ hoa hồng sẽ được chuyển thành BHO với tỉ giá quy đổi ở vòng mở bán công khai (0.005 USD) và sẽ được chuyển tới ví của bạn vào ngày 02 tháng 10 năm 2021',
  'referral.commission_info_message': ' Bạn sẽ nhận được 2F Bonus khi hỗ trợ các nhà đầu tư khác tham gia dự án.',
  'referral.commisision_tier': 'F',
  'thankyou.info_1': 'Tài khoản của bạn đã được kích hoạt!',
  'thankyou.info_2': 'Chúng tôi sẽ chuyển bạn tới Dashboard ngay bây giờ.',
  // Countdown
  'countdown.days': 'Days',
  'countdown.hours': 'Hours',
  'countdown.mins': 'Mins',
  'countdown.secs': 'Secs',

  // sale
  'sale.total_bho_sale': 'Tổng BHO',
  'sale.time_until_next_round': 'Vòng tiếp theo sẽ mở vào',
  'sale.time_until_round': 'Vòng này sẽ mở bán vào',
  'sale.remaining_buy_time': 'Vòng này sẽ đóng vào',
  'sale.public_sale_end': 'Đợt bán công khai đã kết thúc',
  'sale.sold_out': 'Bán hết!',
  // sale statistic
  'statistic.sell_all': 'Tất cả BHO cho vòng này đã được bán hết! Xin hãy trở lại ở vòng tiếp theo!',
  'statistic.buy_all': 'Bạn đã mua số lượng tối đa BHO ở vòng này! Xin hãy trở lại ở vòng tiếp theo!',
  // transaction status
  rejected: 'Từ chối',
  cancelled: 'Huỷ',
  pending: 'Đang chờ',
  in_progress: 'Đang xử lý',
  completed: 'Hoàn thành',
  accepted: 'Chấp nhận',
  // network
  'network.error': 'Số lượng truy cập đang quá đông trong thời điểm này. Xin vui lòng thử lại sau 30 giây.',

  // staking_detail
  'staking_detail.pending': 'Đang stake',
  'staking_detail.released': 'Đã trả',
  'staking_detail.amount': 'Số lượng',
  'staking_detail.transaction_id': 'ID giao dịch',
  'staking_detail.staking_amount': 'Số lượng đang stake',
  'staking_detail.back': 'Trở lại',
  'staking_detail.end_date': 'Ngày kết thúc',

  // create staking
  'create_staking.rewards': 'Lợi nhuận',
  'create_staking.amount': 'Số lượng',
  'create_staking.value_locked': 'Tổng lượng stake',
  'create_staking.min_amount': 'Tối thiểu',
  'create_staking.cannot_stake_less_than': 'Không thể stake ít hơn',
  'create_staking.bho_a_time': 'BHO 1 lần',
  'create_staking.your_wallet_address': 'Địa chỉ ví của bạn',
  'create_staking.your_trust_wallet_address': 'Địa chỉ ví TRUST của bạn',
  'create_staking.your_wallet_address_note': '(Địa chỉ ví mà bạn sẽ dùng để gửi tiền đi và nhận token BHO về)',
  'create_staking.your_trust_wallet_address_note':
    '(Địa chỉ ví TRUST mà bạn sẽ dùng để gửi tiền đi và nhận token BHO về)',
  'create_staking.our_wallet_address': 'Địa chỉ ví của Bholdus',
  'create_staking.our_wallet_address_staking_program': 'chương trình staking',
  'create_staking.our_wallet_address_note': '(Chỉ gửi BHO vào đúng địa chỉ này)',
  'create_staking.warning': 'Lưu ý',
  'create_staking.final_action_text': 'Sau khi chuyển BHO thành công vào ví Bholdus ở trên, bấm',
  'create_staking.confirm_request': 'Xác nhận giao dịch',
  'create_staking.cancel': 'Huỷ',
  'create_staking.deposit': 'Deposit',
  'create_staking.amount_required': 'Cần nhập số lượng',
  'create_staking.total_reward': 'Tổng lợi nhuận',
  'create_staking.back': 'Trở lại',
  'create_staking.program_list': 'Chương trình stake',
  'create_staking.program': 'Chương trình',
  'create_staking.current_staking': 'BHO đang stake',
  'create_staking.capital_return_after_90_day': 'Vốn trả sau 90 ngày',
  'create_staking.interest_pay_after_90_day': 'Lãi trả sau 90 ngày',
  'create_staking.interest_pay_after_every_30_day': 'Lãi trả sau mỗi 30 ngày',
  'create_staking.make_payment': 'Thanh toán',
  'create_staking.make_payment_content':
    'Tiến hành thanh toán cho ví chúng tôi. Hãy đảm bảo rằng bạn dùng địa chỉ ví này để thanh toán:',
  'create_staking.delay_message':
    'Chúng tôi sẽ tạo giao dịch staking ngay khi nhận được thanh toán của bạn. {br}Quy trình khởi tạo có thể bị chậm ít phút. Xin hãy quay lại và kiểm tra sau.',
  'create_staking.changed_message': 'Các chương trình staking mới đang được mở tại {br}',
  // staking round info
  'staking.time_until_round': 'Sự kiện Staking Rewards sẽ bắt đầu trong',
  'staking.remaining_stake_time': 'Sự kiện Staking Rewards sẽ kết thúc trong',
  'staking.staking_end': 'Sự kiện Staking Rewards đã kết thúc',
  'staking.guide': 'Sự kiện Staking Rewards là gì?',

  // term
  apy: 'Lorem ipsum',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'vi',
  antdData,
  messages,
};
