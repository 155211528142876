/* eslint-disable no-undef */
import React from 'react';
import { Input, Button, Form, Select } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { userSelector } from 'redux/user/reducers';
import actions from 'redux/user/actions';
import { analysePassword } from 'utils/analysePassword';
import { analyseEmail } from 'utils/analyseEmail';
import { RecaptchaComponent } from 'components/Recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Link } from 'react-router-dom';
import styles from './style.module.scss';

const { Option } = Select;

const SignupStep1 = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { referralCode, referralType } = props;
  // const locale = store.get('locale');

  const { executeRecaptcha } = useGoogleReCaptcha();

  // useEffect(() => {
  //   if (user.account_status) {
  //     next();
  //   }
  // }, [user]);

  const onFinish = async (values) => {
    try {
      const token = await executeRecaptcha('SignUp');
      dispatch({
        type: actions.REGISTER,
        payload: {
          ...values,
          referral_code: referralCode,
          referral_type: referralType,
          captchaToken: token,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const derivedLocale = user.locale || 'en';

  return (
    <div className={styles.form}>
      <Form
        form={form}
        layout='vertical'
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{ locale: derivedLocale }}
        className={styles.form_field}
      >
        <Form.Item
          label={<FormattedMessage id='signup.email' />}
          name='username'
          rules={[
            {
              type: 'email',
              message: <FormattedMessage id='signup.email_invalid' />,
            },
            () => ({
              validator(_, value) {
                if (value && !analyseEmail(value)) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({
                        id: 'signup.email_invalid',
                      })
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
            {
              required: true,
              message: <FormattedMessage id='signup.email_required' />,
            },
          ]}
          className={styles.field}
        >
          <Input size='large' className={styles.sign_up_input} type='email' />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='signup.password' />}
          name='password'
          className={`${styles.field} ${styles.width_45}`}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='signup.password_required' />,
            },
            () => ({
              validator(_, value) {
                if (value && !analysePassword(value)) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({
                        id: 'signup.strong_password_required',
                      })
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            size='large'
            className={styles.sign_up_input}
            iconRender={(visible) =>
              visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
            }
          />
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id='signup.confirm_password' />}
          name='confirm_password'
          dependencies={['password']}
          className={`${styles.field} ${styles.width_45}`}
          rules={[
            {
              required: true,
              message: <FormattedMessage id='signup.confirm_password_required' />,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    intl.formatMessage({
                      id: 'signup.match_password_required',
                    })
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password
            size='large'
            className={styles.sign_up_input}
            iconRender={(visible) =>
              visible ? <EyeOutlined style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
            }
          />
        </Form.Item>
        {/* TODO: move 2  Form.Item to array and use .reverse() */}
        {derivedLocale === 'vi' ? (
          <>
            <Form.Item
              label={<FormattedMessage id='signup.last_name' />}
              name='last_name'
              className={`${styles.field} ${styles.width_45}`}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id='signup.last_name_required' />,
                },
              ]}
            >
              <Input size='large' className={styles.sign_up_input} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='signup.first_name' />}
              name='first_name'
              className={`${styles.field} ${styles.width_45}`}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id='signup.first_name_required' />,
                },
              ]}
            >
              <Input size='large' className={styles.sign_up_input} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label={<FormattedMessage id='signup.first_name' />}
              name='first_name'
              className={`${styles.field} ${styles.width_45}`}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id='signup.first_name_required' />,
                },
              ]}
            >
              <Input size='large' className={styles.sign_up_input} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='signup.last_name' />}
              name='last_name'
              className={`${styles.field} ${styles.width_45}`}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id='signup.last_name_required' />,
                },
              ]}
            >
              <Input size='large' className={styles.sign_up_input} />
            </Form.Item>
          </>
        )}
        <Form.Item label={<FormattedMessage id='signup.language' />} name='locale' className={styles.field}>
          <Select
            className={styles.locale_input}
            dropdownStyle={{
              backgroundColor: '#252d4b',
              border: '1px solid #1FC7D4',
            }}
          >
            <Option value='en'>English</Option>
            <Option value='vi'>Tiếng Việt</Option>
          </Select>
        </Form.Item>
      </Form>
      <div className={styles.signup_button_wrapper}>
        <Button className={styles.sign_up_button} onClick={() => form.submit()} loading={user.loading}>
          <FormattedMessage id='signup.signup' />
        </Button>
      </div>
      <div className={styles.already_have_account_text}>
        <FormattedMessage id='signup.if_already_have_account' />.{' '}
        <Link
          className={styles.login_here_text}
          to={{
            pathname: '/login',
            state: { step: 2 },
          }}
        >
          <FormattedMessage id='signup.login_here' />
        </Link>
      </div>
    </div>
  );
};

export default RecaptchaComponent(SignupStep1);
