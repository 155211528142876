import { combineReducers } from 'redux';
import { appendByIds } from 'utils/convertData';
import actions from 'redux/user/actions';
import { FETCH_STAKE_PROGRAM_LIST } from './actions';

const initialState = {
  isFetching: false,
  total: 0,
  currentOffset: 0,
};

export const fetchStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAKE_PROGRAM_LIST.PENDING:
      return { ...state, isFetching: true };
    case FETCH_STAKE_PROGRAM_LIST.SUCCESS:
      return {
        isFetching: false,
        total: action.payload.total,
        currentOffset: action.payload.currentOffset,
      };
    case FETCH_STAKE_PROGRAM_LIST.ERROR:
      return { ...state, isFetching: false };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STAKE_PROGRAM_LIST.SUCCESS:
      return appendByIds(state, action.payload.data, 'id');
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_STAKE_PROGRAM_LIST.SUCCESS:
      return action.payload.data.map((item) => item.id);
    case actions.LOGOUT:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId,
});

// Selector here
export const getStakeProgramsList = (state) =>
  state.stakeProgram.visibleIds
    .map((id) => {
      const prg = state.stakeProgram.byId[id];
      if (['program_1', 'program_2'].indexOf(prg.type) === -1) prg.ended = false;
      else prg.ended = true;
      return prg;
    })
    .sort((a, b) => a.ended - b.ended);

export const getFetchStatus = (state) => state.stakeProgram.fetchStatus;
