import lodash from 'lodash';
import { combineReducers } from 'redux';
import { appendByIds } from 'utils/convertData';
import actions from 'redux/user/actions';
import { FETCH_CURRENCY_RATE, FETCH_PAYMENT_METHOD_LIST } from './actions';

const initialState = {
  isFetching: false,
  total: 0,
};

export const fetchStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_LIST.PENDING:
      return { ...state, isFetching: true };
    case FETCH_PAYMENT_METHOD_LIST.SUCCESS:
      return {
        isFetching: false,
        total: action.payload.total,
        savedAt: action.payload.savedAt,
      };
    case FETCH_PAYMENT_METHOD_LIST.ERROR:
      return { ...state, isFetching: false };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_LIST.SUCCESS:
      return appendByIds(state, action.payload.data, 'id');
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_LIST.SUCCESS:
      return action.payload.data.map((item) => item.id);
    case actions.LOGOUT:
      return [];
    default:
      return state;
  }
};

export const currencyRate = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_RATE.SUCCESS:
      return { ...state, ...action.payload };
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  visibleIds,
  byId,
  currencyRate,
});

// Selector here
export const getPaymentMethodList = (state) => state.paymentMethod.visibleIds.map((id) => state.paymentMethod.byId[id]);
export const getAllPaymentMethodList = (state) => lodash.values(state.paymentMethod.byId);
export const getFetchStatus = (state) => state.paymentMethod.fetchStatus;
export const getSavedAtPaymentMethod = (state) => state.paymentMethod.fetchStatus.savedAt;
export const getCurrencyRate = (state) => state.paymentMethod.currencyRate;
