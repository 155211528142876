import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import analytics from 'utils/analytics';
import { BASE_URL } from 'configs';
import styles from './style.module.scss';
import Step1 from './step1';
import Step2 from './step2';

class ForgotPass extends Component {
  state = {
    step: 0,
    username: '',
  };

  componentDidMount = () => {
    analytics.sendEvent({
      action: 'forgot_password_screen',
      label: 'visit_screen',
    });
  };

  next = (values) => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
      username: values.username,
    }));
  };

  render() {
    const { step, username } = this.state;
    return (
      <div>
        <Helmet title='Forgot password' />

        <div className={styles.block}>
          <div className={styles.inner}>
            <div className={styles.title}>
              <FormattedMessage id='forgot_password.title' />
            </div>
            <Link to={`${BASE_URL}/login`} className={styles.back_arrow}>
              <ArrowLeftOutlined style={{ fontSize: '20px' }} />
            </Link>

            {step === 0 && <Step1 next={this.next} />}
            {step === 1 && <Step2 username={username} />}
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPass;
