import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// import { FormattedMessage } from 'react-intl';
import lodash from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { getFetchStatus } from 'redux/commission/reducers';
import { FETCH_COMMISSION_STATISTICS } from 'redux/commission/actions';
import { userSelector } from 'redux/user/reducers';
import styles from './style.module.scss';

const STAKING_TAB_SWITCH_ID = 'STAKING_TAB_SWITCH_ID';
const DASHBOARD_SWITCH_ID = 'DASHBOARD_SWITCH_ID';
const REFERRAL_TAB_SWITCH_ID = 'REFERRAL_TAB_SWITCH_ID';

const LIST_TAB_SWITCH = [
  {
    id: STAKING_TAB_SWITCH_ID,
    url: '/staking',
    textButton: 'STAKING',
  },
  {
    id: DASHBOARD_SWITCH_ID,
    url: '/dashboard',
    textButton: 'BHO SALES',
  },
  // {
  //   id: REFERRAL_TAB_SWITCH_ID,
  //   url: '/referral-manager',
  //   textButton: <FormattedMessage id='referral.title' />,
  // },
];

const NavigationTabSwitch = () => {
  const location = useLocation();
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  const dispatch = useDispatch();

  const referralManagerState = useSelector(getFetchStatus);
  const user = useSelector(userSelector);

  const isHideReferral = lodash.get(referralManagerState, 'total_commissions') <= 0;
  const displayTabSwitchList = isHideReferral ? LIST_TAB_SWITCH.slice(0, 2) : LIST_TAB_SWITCH;

  useEffect(() => {
    const { pathname } = location;
    if (!/^\/referral-manager(?=\/|$)/i.test(pathname) && referralManagerState.isFetching === null) {
      dispatch({
        type: FETCH_COMMISSION_STATISTICS.REQUEST,
        payload: {
          user_id: user.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location) {
      let targetActiveTab = STAKING_TAB_SWITCH_ID;
      const { pathname } = location;
      if (/^\/staking(?=\/|$)/i.test(pathname)) {
        targetActiveTab = STAKING_TAB_SWITCH_ID;
      }
      if (/^\/dashboard(?=\/|$)/i.test(pathname)) {
        targetActiveTab = DASHBOARD_SWITCH_ID;
      }
      if (/^\/referral-manager(?=\/|$)/i.test(pathname)) {
        targetActiveTab = REFERRAL_TAB_SWITCH_ID;
      }

      setCurrentActiveTab(targetActiveTab);
    }
  }, [location]);

  return (
    <div className={styles.navigation_button}>
      {displayTabSwitchList.map((itemSwitch, index) => {
        const isActive = currentActiveTab === itemSwitch.id;
        return (
          <Link to={itemSwitch.url} key={String(index)}>
            <Button className={[styles.tab_button, isActive ? styles.active_button : styles.inactive_button]}>
              {itemSwitch.textButton}
            </Button>
            <div className={[styles.tab_bar, isActive ? styles.active_bar : styles.inactive_bar].join(' ')} />
          </Link>
        );
      })}
    </div>
  );
};

export default NavigationTabSwitch;
