import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import NProgress from "nprogress";
import { Helmet } from 'react-helmet';
import ErrorBoundary from 'components/LayoutComponents/ErrorBoundary';
import actions from 'redux/user/actions';
import lodash from 'lodash';
import analytics from '../utils/analytics';
import DashboardLayout from './DashboardLayout';
import MainLayout from './Main';

const Layouts = {
  main: MainLayout,
  dashboard: DashboardLayout,
};

class IndexLayout extends React.PureComponent {
  previousPath = '';

  componentDidMount() {
    const { location, user, dispatch } = this.props;
    analytics.init();
    analytics.sendPageview(location.pathname);
    if (lodash.get(user, 'jwt_token')) {
      dispatch({
        type: actions.LOAD_CURRENT_ACCOUNT,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { prevLocation } = prevProps;
    if (location !== prevLocation) {
      // window.scrollTo(0, 0);

      // send update pageview
      const currentPath = location.pathname + location.search;
      analytics.sendPageview(currentPath);
    }
  }

  render() {
    const { children, user, location } = this.props;

    // // NProgress Management
    // const currentPath = pathname + search;
    // if (currentPath !== this.previousPath) {
    //   NProgress.start();
    // }

    // setTimeout(() => {
    //   NProgress.done();
    //   this.previousPath = currentPath;
    // }, 300);

    // Layout Rendering
    const getLayout = () =>
      // pathname
      // if (/^\/dashboard(?=\/|$)/i.test(pathname)) {
      //   return 'dashboard';
      // }
      // if (/^\/referral-manager(?=\/|$)/i.test(pathname)) {
      //   return 'dashboard';
      // }
      // if (/^\/staking(?=\/|$)/i.test(pathname)) {
      //   return 'dashboard';
      // }
      // if (/^\/request-detail(?=\/|$)/i.test(pathname)) {
      //   return 'dashboard';
      // }
      'main';
    const Container = Layouts[getLayout(location.pathname)];

    const BootstrappedLayout = () => (
      <Container user={user} location={location}>
        {children}
      </Container>
    );
    return (
      <>
        <Helmet titleTemplate='Bholdus | %s' title='Bholdus' />
        <ErrorBoundary>{BootstrappedLayout()}</ErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(IndexLayout));
