/* eslint-disable camelcase */
import dayjs from 'dayjs';

export const getCurrentSaleStage = (data) => {
  const { sale_end_time, sale_start_time, next } = data;
  const next_sale_start_time = next?.sale_start_time;
  const saleStartTime = dayjs(sale_start_time);
  const saleEndTime = dayjs(sale_end_time);
  const nextSaleTime = dayjs(next_sale_start_time);
  if (!saleStartTime.isValid() || !saleEndTime.isValid()) return null;

  if (saleStartTime.diff(dayjs()) > 0) {
    return 'is_waiting_for_sale';
  }
  if (saleEndTime.diff(dayjs()) > 0) {
    return 'is_selling';
  }
  if (saleEndTime.diff(dayjs()) < 0) {
    if (next_sale_start_time && nextSaleTime.diff(dayjs()) > 0) {
      return 'is_waiting_next_sale';
    }
  }
  return 'end_sale';
};

export const getCurrentRegistrationStage = (data) => {
  const { registration_end_time, registration_start_time, next } = data;
  const next_registration_start_time = next?.registration_start_time;
  const registrationStartTime = dayjs(registration_start_time);
  const registrationEndTime = dayjs(registration_end_time);
  const nextRegistration = dayjs(next_registration_start_time);

  if (!registrationStartTime.isValid() || !registrationEndTime.isValid()) return null;

  if (registrationStartTime.diff(dayjs()) > 0) {
    return 'is_waiting_for_registration';
  }
  if (registrationEndTime.diff(dayjs()) > 0) {
    return 'is_registration';
  }
  if (registrationEndTime.diff(dayjs()) < 0) {
    if (next_registration_start_time && nextRegistration.diff(dayjs()) > 0) {
      return 'is_waiting_next_registration';
    }
  }
  return 'end_registration';
};

export const isMoreThanAHour = (dateISOString) => {
  const savedAtDate = new Date(dateISOString);
  if (Number.isNaN(savedAtDate.getTime())) {
    // NOTE: no data for savedAt
    return true;
  }
  if (new Date().getTime() > new Date(dateISOString).getTime() + 3600000) {
    return true;
  }

  return false;
};
