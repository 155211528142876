import React, { Component } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import lodash from 'lodash';
import { ethers } from 'ethers';
import { connect } from 'react-redux';
import { showError } from 'utils/toastMessage';

import actions from 'redux/user/actions';
import analytics from 'utils/analytics';
import Image from 'components/Image';
import { BSC_SCAN_URL } from 'configs';
import styles from './style.module.scss';

class WalletPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_visible: false,
      wallet_address: lodash.get(props, 'user.wallet_addresses[0].wallet_address', ''),
    };
  }

  componentDidMount = () => {
    const { wallet_address } = this.state;
    if (!wallet_address) {
      analytics.sendEvent({
        action: 'add_wallet_screen',
        label: 'visit_screen',
      });
    } else {
      analytics.sendEvent({
        action: 'view_wallet_screen',
        label: 'visit_screen',
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { user } = props;
    const { wallet_address } = state;
    // isFetching for waiting asynchronous store update when editing
    if (user && !wallet_address) {
      return {
        ...state,
        wallet_address: lodash.get(user, 'wallet_addresses[0].wallet_address', ''),
      };
    }
    return state;
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onFinish = () => {
    const { wallet_address } = this.state;
    if (!ethers.utils.isAddress(wallet_address)) {
      showError('Wallet.invalid_wallet');
      return;
    }
    this.showModal();
  };

  handleOk = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { wallet_address } = this.state;
    dispatch({
      type: actions.ADD_WALLET,
      payload: {
        wallet_address,
      },
    });
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      visible: false,
    });
  };

  render() {
    const { user, intl } = this.props;
    const { wallet_address, visible } = this.state;
    return (
      <div className={styles.wallet}>
        <Helmet title='Dashboard' />
        <div className={styles.block}>
          <div className={styles.inner}>
            <div className={styles.wallet_image}>
              <Image src='/images/wallet.svg' alt='wallet' />
            </div>
            {user?.wallet_addresses.length === 0 ? (
              <>
                <div className={styles.message}>
                  <FormattedMessage id='wallet.add_wallet_message' />
                </div>
                <div className={styles.wanring}>
                  <ul>
                    <li>
                      <FormattedMessage id='wallet.warning_one' />
                    </li>
                    <li>
                      <FormattedMessage id='wallet.warning_two' />
                    </li>
                    <li>
                      <FormattedMessage id='wallet.warning_three' />
                    </li>
                  </ul>
                </div>
              </>
            ) : null}
            <Form layout='vertical' hideRequiredMark className={styles.form}>
              <Form.Item label={<FormattedMessage id='wallet.wallet_address' />} className={styles.input_wrapper}>
                <Input
                  value={wallet_address}
                  onChange={(e) => this.setState({ wallet_address: e.target.value })}
                  className={styles.input}
                  disabled={user?.wallet_addresses.length !== 0}
                />
              </Form.Item>
              <div>
                <Link
                  className={styles.check_address}
                  to={{
                    pathname: `${BSC_SCAN_URL}/address/${wallet_address}`,
                  }}
                  target='_blank'
                >
                  <FormattedMessage id='wallet.check_your_wallet' />
                </Link>
              </div>
              <div className={styles.wallet_guide_wrapper}>
                <Link
                  className={styles.wallet_guide_text}
                  to={{
                    pathname: intl.formatMessage({
                      id: 'wallet.guide_url',
                    }),
                  }}
                  target='_blank'
                >
                  <QuestionCircleOutlined
                    className={styles.question_icon}
                    style={{ color: 'white', fontWeight: 'bold' }}
                  />
                  <FormattedMessage id='wallet.guide' />
                </Link>
              </div>
            </Form>
          </div>

          {user?.wallet_addresses.length === 0 ? (
            <Button className={styles.add_button} onClick={this.onFinish}>
              <FormattedMessage id='wallet.add_wallet' />
            </Button>
          ) : null}

          <Modal
            title={intl.formatMessage({
              id: 'wallet.confirm_add_wallet',
            })}
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            className={styles.modal}
          >
            <p>
              <FormattedMessage id='wallet.confirm_wallet' />
            </p>
            <p>
              <Link
                className='link'
                to={{
                  pathname: `${BSC_SCAN_URL}/address/${wallet_address}`,
                }}
                target='_blank'
              >
                {wallet_address}
              </Link>
            </p>
            <p>
              <FormattedMessage id='wallet.warning_three' />
            </p>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default injectIntl(connect(mapStateToProps)(WalletPage));
