import actions from './actions';

const initialState = {
  code: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_REFERAL_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const referralSelector = (state) => state.referral;
