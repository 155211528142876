/* eslint-disable camelcase */
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getList, getCommissionStatistics, getCommissionScheme } from 'services/commission';
import lodash from 'lodash';
import { showError } from 'utils/toastMessage';
// import { SET_ERROR } from 'redux/error/actions';
import { FETCH_COMMISSION_LIST, FETCH_COMMISSION_STATISTICS, FETCH_COMMISSION_SCHEME } from './actions';

export function* REQUEST_COMMISSION_LIST({ payload }) {
  yield put({
    type: FETCH_COMMISSION_LIST.PENDING,
  });
  try {
    const { offset, limit } = payload;
    const response = yield call(getList, offset, limit);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_COMMISSION_LIST.ERROR,
      });
    } else {
      yield put({
        type: FETCH_COMMISSION_LIST.SUCCESS,
        payload: {
          data: (response.data && response.data.data) || [],
          total: response.data && response.data.total,
          currentOffset: offset + (limit || 10),
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_COMMISSION_LIST.ERROR,
    });
  }
}

export function* REQUEST_COMMISSION_STATISTICS({ payload }) {
  yield put({
    type: FETCH_COMMISSION_STATISTICS.PENDING,
  });
  try {
    const { user_id } = payload;
    const response = yield call(getCommissionStatistics, user_id);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_COMMISSION_STATISTICS.ERROR,
      });
    } else {
      yield put({
        type: FETCH_COMMISSION_STATISTICS.SUCCESS,
        payload: {
          total_referrals: response.data.data.total_referrals,
          total_commissions: response.data.data.total_commissions,
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_COMMISSION_STATISTICS.ERROR,
    });
  }
}

export function* REQUEST_COMMISSION_SCHEME() {
  yield put({
    type: FETCH_COMMISSION_SCHEME.PENDING,
  });
  try {
    const response = yield call(getCommissionScheme);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_COMMISSION_SCHEME.ERROR,
      });
    } else {
      yield put({
        type: FETCH_COMMISSION_SCHEME.SUCCESS,
        payload: {
          data: response.data.data,
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_COMMISSION_SCHEME.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_COMMISSION_LIST.REQUEST, REQUEST_COMMISSION_LIST),
    takeEvery(FETCH_COMMISSION_STATISTICS.REQUEST, REQUEST_COMMISSION_STATISTICS),
    takeEvery(FETCH_COMMISSION_SCHEME.REQUEST, REQUEST_COMMISSION_SCHEME),
  ]);
}
