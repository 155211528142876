import { all, call, put, takeEvery } from 'redux-saga/effects';

import lodash from 'lodash';
import {
  getList,
  getDetail,
  createTransaction,
  confirmPayment,
  cancelTransaction,
  getCurrentTotal,
} from 'services/transaction';
// import { SET_ERROR } from 'redux/error/actions';
import { showError } from 'utils/toastMessage';
import history from 'routerHistory';
import { BASE_URL } from 'configs';

import {
  FETCH_TRANSACTION_LIST,
  FETCH_TRANSACTION_DETAIL,
  CREATE_TRANSACTION,
  CONFIRM_PAYMENT,
  CANCEL_TRANSACTION,
  FETCH_CURRENT_TOTAL,
} from './actions';

export function* REQUEST_CREATE_TRANSACTION({ payload }) {
  yield put({
    type: CREATE_TRANSACTION.PENDING,
  });
  try {
    const { transactionData } = payload;
    const response = yield call(createTransaction, transactionData);

    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: CREATE_TRANSACTION.ERROR,
      });
    } else {
      yield put({
        type: CREATE_TRANSACTION.SUCCESS,
        payload: {
          data: (response.data && response.data.data) || {},
        },
      });
      history.push(`${BASE_URL}/request-detail/${response.data.data.id}`);
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: CREATE_TRANSACTION.ERROR,
    });
  }
}

export function* REQUEST_TRANSACTION_LIST({ payload }) {
  yield put({
    type: FETCH_TRANSACTION_LIST.PENDING,
  });
  try {
    const { offset, limit } = payload;

    const response = yield call(getList, { ...payload });
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_TRANSACTION_LIST.ERROR,
      });
    } else {
      yield put({
        type: FETCH_TRANSACTION_LIST.SUCCESS,
        payload: {
          data: (response.data && response.data.data) || [],
          currentOffset: offset + (limit || 20),
          total: response.data && response.data.total,
        },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_TRANSACTION_LIST.ERROR,
    });
  }
}

export function* REQUEST_TRANSACTION_DETAIL({ payload }) {
  try {
    const { id } = payload;
    yield put({
      type: FETCH_TRANSACTION_DETAIL.PENDING,
    });
    const response = yield call(getDetail, id);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_TRANSACTION_DETAIL.ERROR,
      });
      history.replace('/dashboard');
    } else {
      yield put({
        type: FETCH_TRANSACTION_DETAIL.SUCCESS,
        payload: { data: (response.data && response.data.data) || null },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_TRANSACTION_DETAIL.ERROR,
    });
  }
}

export function* REQUEST_CONFIRM_PAYMENT({ payload }) {
  yield put({
    type: CONFIRM_PAYMENT.PENDING,
  });
  try {
    const { id, payment } = payload;

    const response = yield call(confirmPayment, id, payment);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: CONFIRM_PAYMENT.ERROR,
      });
    } else {
      yield put({
        type: CONFIRM_PAYMENT.SUCCESS,
        payload: { data: (response.data && response.data.data) || null },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: CONFIRM_PAYMENT.ERROR,
    });
  }
}

export function* REQUEST_CANCEL_TRANSACTION({ payload }) {
  yield put({
    type: CANCEL_TRANSACTION.PENDING,
  });
  try {
    const { id } = payload;

    const response = yield call(cancelTransaction, id);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: CANCEL_TRANSACTION.ERROR,
      });
    } else {
      yield put({
        type: CANCEL_TRANSACTION.SUCCESS,
        payload: { data: (response.data && response.data.data) || null },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: CANCEL_TRANSACTION.ERROR,
    });
  }
}

export function* REQUEST_TOTAL_AMOUNT() {
  yield put({
    type: FETCH_CURRENT_TOTAL.PENDING,
  });
  try {
    const response = yield call(getCurrentTotal);
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_CURRENT_TOTAL.ERROR,
      });
    } else {
      yield put({
        type: FETCH_CURRENT_TOTAL.SUCCESS,
        payload: { ...(response.data && response.data.data) },
      });
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_CURRENT_TOTAL.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_TRANSACTION_LIST.REQUEST, REQUEST_TRANSACTION_LIST),
    takeEvery(FETCH_TRANSACTION_DETAIL.REQUEST, REQUEST_TRANSACTION_DETAIL),
    takeEvery(CREATE_TRANSACTION.REQUEST, REQUEST_CREATE_TRANSACTION),
    takeEvery(CONFIRM_PAYMENT.REQUEST, REQUEST_CONFIRM_PAYMENT),
    takeEvery(CANCEL_TRANSACTION.REQUEST, REQUEST_CANCEL_TRANSACTION),
    takeEvery(FETCH_CURRENT_TOTAL.REQUEST, REQUEST_TOTAL_AMOUNT),
  ]);
}
