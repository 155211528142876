import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import english from 'locales/en';
import vietnamese from 'locales/vi';

const locales = {
  en: english,
  vi: vietnamese,
};

const Localization = (props) => {
  const { children, user } = props;
  const currentLocale = user?.locale && locales[user.locale] ? locales[user.locale] : locales.en;
  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Localization);
