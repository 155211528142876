import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Localization from 'components/LayoutComponents/Localization';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Router from './router';
import './index.scss';

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Localization>
          <Router />
        </Localization>
      </Web3ReactProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
