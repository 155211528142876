import { combineReducers } from 'redux';

import user from './user/reducers';
import transaction from './transaction/reducers';
import paymentMethod from './payment-method/reducers';
import commission from './commission/reducers';
import balance from './balance/reducers';
// eslint-disable-next-line import/no-named-as-default
import saleStatistics from './sale-statistics/reducers';
import saleConfig from './sale-config/reducers';
import stakingConfig from './staking-round-config/reducers';
import referral from './referral/reducers';
import error from './error/reducers';
import stakeProgram from './stake-program/reducers';
import stake from './stake/reducers';
import navbar from './navbar/reducers';

const reducers = () =>
  combineReducers({
    user,
    transaction,
    paymentMethod,
    commission,
    balance,
    saleStatistics,
    saleConfig,
    stakingConfig,
    referral,
    error,
    stakeProgram,
    stake,
    navbar,
  });
export default reducers;
