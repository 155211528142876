import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ProfileMenu from 'components/LayoutComponents/ProfileMenu';
import LanguageSelector from 'components/LayoutComponents/LanguageSelector';
import { NavBar } from 'components/LayoutComponents/NavBar';
import StakingInfo from 'components/StakingInfo';
import { isNavBarOpenSelector } from 'redux/navbar/reducers';
import { SET_NAV_BAR_IS_OPEN } from 'redux/navbar/actions';
import Container from './Container';

import styles from './style.module.scss';

const { Header, Content, Footer } = Layout;

const MainLayout = (props) => {
  const { children, user } = props;
  const isNavBarOpen = useSelector(isNavBarOpenSelector);
  const dispatch = useDispatch();

  return (
    <Layout
      className={styles.main}
      style={{
        background: `url("/images/background-image.jpg") no-repeat center center fixed`,
      }}
    >
      <Header className={styles.header}>
        <Container className={styles.header_container}>
          <MenuOutlined
            className={styles.navbar_toggle_menu_icon}
            onClick={() => dispatch({ type: SET_NAV_BAR_IS_OPEN, payload: !isNavBarOpen })}
          />
          <div className={styles.logo}>
            <Link to={user.authorized ? '/dashboard' : '/login'}>
              <img src='/images/logo-b.png' alt='BHO Network' style={{ width: '100%' }} />
            </Link>
          </div>
          <div className={styles.right_actions}>
            <LanguageSelector user={user} />
            {user.authorized && <ProfileMenu user={user} />}
          </div>
        </Container>
      </Header>
      {/* {user.authorized ? (
        <div id='guide-buy-bho' className={styles.guide}>
          <a href='/#' target='_blank' rel='noreferrer'>
            <FormattedMessage id='staking.guide' />
          </a>
        </div>
      ) : null} */}
      <Content className={styles.layout}>
        <StakingInfo />
        {/* user.authorized &&
        [`${BASE_URL}/staking/program-list`, `${BASE_URL}/staking/staking-list`].indexOf(
          lodash.get(location, 'pathname')
        ) !== -1 ? (
          <StakingInfo />
        ) : null */}
        <Container className={styles.main_container}>
          <div
            role='presentation'
            className={classNames([styles.dim_layer, isNavBarOpen ? styles.dim_layer_display : ''])}
            onClick={() => dispatch({ type: SET_NAV_BAR_IS_OPEN, payload: false })}
          />
          <div className={classNames([styles.navbar, isNavBarOpen ? styles.navbar_mobile_open : ''])}>
            <NavBar />
          </div>
          <div className={styles.content_wrapper}>{children}</div>
        </Container>
      </Content>
      <Footer className={styles.footer} style={{ textAlign: 'center' }}>
        ©2021 Copyright by Unius Pte Ltd
      </Footer>
    </Layout>
  );
};

export default MainLayout;
