import dayjs from 'dayjs';

export const getCurrentSaleStage = (data) => {
  const { sale_end_time, sale_start_time } = data;
  const saleStartTime = dayjs(sale_start_time);
  const saleEndTime = dayjs(sale_end_time);
  if (!saleStartTime.isValid() || !saleEndTime.isValid()) return null;

  if (saleStartTime.diff(dayjs()) > 0) {
    return 'is_waiting_for_staking';
  }
  if (saleEndTime.diff(dayjs()) > 0) {
    return 'is_staking';
  }
  return 'end_staking';
};

export const isMoreThanAHour = (dateISOString) => {
  const savedAtDate = new Date(dateISOString);
  if (Number.isNaN(savedAtDate.getTime())) {
    // NOTE: no data for savedAt
    return true;
  }
  if (new Date().getTime() > new Date(dateISOString).getTime() + 3600000) {
    return true;
  }

  return false;
};
