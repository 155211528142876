import React, { useState } from 'react';
import { Input, Button, Form } from 'antd';
import { get } from 'lodash';
import { RecaptchaComponent } from 'components/Recaptcha';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormattedMessage } from 'react-intl';
import { forgotPassword } from 'services/user';
import { showMessageWithTemplate, showError } from 'utils/toastMessage';
import styles from './style.module.scss';

const Step1 = (props) => {
  const [loading, setLoading] = useState(false);
  const { next } = props;

  const onFinish = async (values) => {
    const { googleReCaptchaProps } = props;
    const { executeRecaptcha } = googleReCaptchaProps;
    const captchaToken = await executeRecaptcha('ForgetPassword');
    try {
      setLoading(true);
      const res = await forgotPassword(values.username, captchaToken);
      if (!res.data.error) {
        setLoading(false);
        showMessageWithTemplate('ForgotPassword.success', { username: values.username });
        next(values);
      } else {
        setLoading(false);
        showError(get(res, 'data.message.messages[0].id'));
      }
    } catch (err) {
      setLoading(false);
      showError('forgot_password.error');
    }
  };

  return (
    <div className={styles.form}>
      <Form layout='vertical' hideRequiredMark onFinish={onFinish}>
        <Form.Item
          label={<FormattedMessage id='forgot_password.email' />}
          name='username'
          rules={[
            {
              required: true,
              message: <FormattedMessage id='forgot_password.email_required' />,
            },
          ]}
        >
          <Input size='large' className={styles.mail_input} type='email' />
        </Form.Item>
        <Form.Item>
          <Button className={styles.send_mail_button} htmlType='submit' loading={loading}>
            <FormattedMessage id='forgot_password.send_mail' />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RecaptchaComponent(withGoogleReCaptcha(Step1));
