import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Countdown from 'components/CountDown';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { getRoundSetting } from 'redux/staking-round-config/reducers';
import { getCurrentSaleStage, isMoreThanAHour } from 'utils/staking';
import { FETCH_ROUND_SETTING, UPDATE_ROUND_SETTING } from 'redux/sale-config/actions';
import { UPDATE_SALE_STATISTICS } from 'redux/sale-statistics/actions';
import { FETCH_STAKING_ROUND_SETTING } from 'redux/staking-round-config/actions';
import styles from './styles.module.scss';

const StakingInfo = ({ className = '', createRequestPage = false, saleStartTime = null }) => {
  const dispatch = useDispatch();
  const roundSetting = useSelector(getRoundSetting);
  const currentStakingStage = getCurrentSaleStage(roundSetting);

  const getTime = () => {
    if (currentStakingStage === 'is_waiting_for_staking') return dayjs(roundSetting.sale_start_time);
    if (currentStakingStage === 'is_staking') return dayjs(roundSetting.sale_end_time);
  };

  const getDesc = () => {
    if (currentStakingStage === 'is_waiting_for_staking') return <FormattedMessage id='staking.time_until_round' />;
    if (currentStakingStage === 'is_staking') return <FormattedMessage id='staking.remaining_stake_time' />;
    if (currentStakingStage === 'end_staking') return <FormattedMessage id='staking.staking_end' />;
    return null;
  };

  const [countDownTime, setCountDownTime] = useState(getTime());
  const [description, setDescription] = useState(getDesc());

  useEffect(() => {
    dispatch({
      type: FETCH_STAKING_ROUND_SETTING.REQUEST,
    });
    dispatch({ type: UPDATE_SALE_STATISTICS.REQUEST });
    // NOTE: saleStartTime = null mean call by default
    if (
      saleStartTime === null ||
      (createRequestPage && (saleStartTime === '' || isMoreThanAHour(roundSetting.savedAt)))
    ) {
      // NOTE: condition check of CreateRequestPage
      dispatch({
        type: FETCH_ROUND_SETTING.REQUEST,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCountDownTime(getTime());
    setDescription(getDesc());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStakingStage]);

  if (!roundSetting) return null;
  // TODO: if (roundSetting.isFetching) return <Loading />

  const onCountdownComplete = () => {
    setTimeout(() => {
      dispatch({
        type: UPDATE_ROUND_SETTING,
        payload: {
          ...roundSetting,
          updatedAt: new Date().toISOString(),
        },
      });
    }, 1000);
    // window.location.reload();
  };

  return (
    <div className={classNames(styles.sale_container, className)}>
      <div className={styles.sale}>
        <p>{description}</p>
        <Countdown key={countDownTime} dateTime={countDownTime} card={false} onComplete={onCountdownComplete} />
      </div>
    </div>
  );
};

export default StakingInfo;
