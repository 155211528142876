import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import ProfileMenu from 'components/LayoutComponents/ProfileMenu';
import LanguageSelector from 'components/LayoutComponents/LanguageSelector';
import StakingInfo from 'components/StakingInfo';
import { FormattedMessage } from 'react-intl';
import { BASE_URL } from 'configs';
import Image from 'components/Image';
// import SaleStatistic from 'components/SaleStatistic';
// import lodash from 'lodash';
// import BuyButton from 'components/BuyButton';
import styles from './style.module.scss';

const { Header, Content, Footer } = Layout;

const MainLayout = (props) => {
  const { children, user, location } = props;
  // const intl = useIntl();
  // const buy_url = intl.formatMessage({
  //   id: 'guide.url', // This ID could be anything else as well
  // });
  const isValidURL = () => {
    if (location && location.pathname) {
      const validURLs = [`/staking`, `/dashboard`, `/referral-manager`, `/create-request`];
      return validURLs.some((url) => {
        if (location.pathname.indexOf(url) > -1) {
          return true;
        }
        return false;
      });
    }
    return false;
  };

  const guideLink = useMemo(
    () =>
      user.locale === 'en'
        ? 'https://bho.network/blog/article/bholdus-launches-staking-rewards-event-offering-up-to-50percent-interest'
        : 'https://bho.network/vi/blog/article/bholdus-launches-staking-rewards-event-offering-up-to-50percent-interest',
    [user.locale]
  );

  return (
    <Layout
      className={styles.main}
      style={{
        background: `url("${BASE_URL}/images/background-image.jpg") no-repeat center center fixed`,
      }}
    >
      <Header className={styles.header}>
        <div className={styles.logo}>
          <Link to={user.authorized ? `${BASE_URL}/staking` : `${BASE_URL}/login`}>
            <Image src='/images/logo-b.png' alt='BHO Network' />
          </Link>
        </div>
        <div className={styles.float_right}>
          <LanguageSelector user={user} />
          {user.authorized && <ProfileMenu user={user} />}
        </div>
      </Header>
      {user.authorized ? (
        <div id='guide-buy-bho' className={styles.guide}>
          <a href={guideLink} target='_blank' rel='noreferrer'>
            <FormattedMessage id='staking.guide' />
          </a>
        </div>
      ) : null}
      <Content className={styles.layout}>
        {user.authorized && isValidURL() ? <StakingInfo /> : null}
        {/* <SaleStatistic /> */}
        {/* <BuyButton key='main_buy_button' className={styles.buy_button} /> */}

        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2021 Copyright by BHO Network</Footer>
      <div />
    </Layout>
  );
};

export default MainLayout;
