/* eslint-disable react/jsx-props-no-spreading */
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import { RECAPTCHA_SITEKEY } from 'configs';

const RecaptchaComponent = (Component) => {
  const Recaptcha = ({ children }) => (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>{children}</GoogleReCaptchaProvider>
  );
  // eslint-disable-next-line react/prefer-stateless-function
  return class Higher extends React.Component {
    render() {
      return (
        <Recaptcha>
          <Component {...this.props} />
        </Recaptcha>
      );
    }
  };
};
export { RecaptchaComponent };
