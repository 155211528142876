import React, { Component, Fragment } from 'react';
import { Table, Button } from 'antd';
import { Helmet } from 'react-helmet';
import NumberFormat from 'react-number-format';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { addKeyToObjects } from 'utils/convertData';
import Progress from 'components/ProgressBar';
// import BuyButton from 'components/BuyButton';
import { getTransactionList, getFetchStatus } from 'redux/transaction/reducers';
import { getBalance } from 'redux/balance/reducers';
import { FETCH_TRANSACTION_LIST } from 'redux/transaction/actions';
import { FETCH_BALANCE } from 'redux/balance/actions';
import analytics from 'utils/analytics';
import Image from 'components/Image';
// import { FETCH_PAYMENT_METHOD_LIST } from 'redux/payment-method/actions';
import { getAllPaymentMethodList, getSavedAtPaymentMethod } from 'redux/payment-method/reducers';
import {
  BASE_URL,
  //  SITE_ADDRESS
} from 'configs';
// import { isMoreThanAHour } from 'utils/sale';
import NavigationTabSwitch from 'components/NavigationTabSwitch';
import styles from './style.module.scss';
// import InfoMessage from './Info';

class Dashboard extends Component {
  state = {
    currentPage: 1,
    pageSize: 6,
  };

  // onPreLoadForCreateRequestPage = () => {
  //   const { dispatch, allPaymentMethodList, user, savedAtPaymentMethod } = this.props;

  //   const isSavedPaymentLocaleMatched = () => allPaymentMethodList.some((method) => method.locale === user.locale);

  //   if (allPaymentMethodList.length === 0 || isMoreThanAHour(savedAtPaymentMethod) || !isSavedPaymentLocaleMatched()) {
  //     dispatch({
  //       type: FETCH_PAYMENT_METHOD_LIST.REQUEST,
  //       payload: {
  //         offset: 0,
  //         limit: -1,
  //         locale: user.locale,
  //       },
  //     });
  //   }
  // };

  componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;

    // this.onPreLoadForCreateRequestPage();

    dispatch({
      type: FETCH_TRANSACTION_LIST.REQUEST,
      payload: {
        offset: 0,
        limit: pageSize,
      },
    });
    dispatch({
      type: FETCH_BALANCE.REQUEST,
      payload: {},
    });

    analytics.sendEvent({
      action: 'dashboard_screen',
      label: 'visit_screen',
    });
  }

  loadMore = (limit) => {
    const { fetchStatus, dispatch } = this.props;

    dispatch({
      type: FETCH_TRANSACTION_LIST.REQUEST,
      payload: {
        offset: fetchStatus.currentOffset,
        limit,
      },
    });
  };

  onPageChange = (pageNumber) => {
    const { fetchStatus } = this.props;
    const { pageSize } = this.state;
    this.setState({
      currentPage: pageNumber,
    });
    const limit = pageNumber * pageSize - fetchStatus.currentOffset;
    if (limit > 0) {
      this.loadMore(limit);
    }
  };

  render() {
    const { balance, data, fetchStatus } = this.props;
    const { currentPage, pageSize } = this.state;

    const tableColumns = [
      {
        title: <FormattedMessage id='dashboard.id' />,
        dataIndex: 'key',
        align: 'center',
        render: (id) => `#${id}`,
        width: 50,
      },
      {
        title: <FormattedMessage id='dashboard.created_date' />,
        dataIndex: 'created_at',
        align: 'center',
        render: (date) => dayjs(date).format('DD/MM/YYYY'),
        width: 100,
      },
      {
        title: <FormattedMessage id='dashboard.amount_of_bho' />,
        dataIndex: 'transaction',
        align: 'center',
        render: (transaction) => (
          <NumberFormat thousandSeparator=',' value={transaction.out_amount} displayType='text' decimalScale={3} />
        ),
        width: 120,
      },
      {
        title: <FormattedMessage id='dashboard.you_pay' />,
        dataIndex: 'transaction',
        align: 'center',
        render: (transaction) => (
          <>
            <NumberFormat thousandSeparator=',' value={transaction.in_amount} displayType='text' />
            {` ${transaction.in_currency}`}
          </>
        ),
        width: 150,
      },
      {
        title: <FormattedMessage id='dashboard.release_status' />,
        dataIndex: 'transaction_locks',
        align: 'center',
        render: (transaction_locks) => {
          let percent = 0;
          transaction_locks.forEach((lock) => {
            if (lock.status === 'released') {
              percent += lock.release_percent;
            }
          });

          return <Progress percent={percent} width={isMobile ? 70 : 140} color='#1FC7D4' backgroundColor='#C4C4C4' />;
        },
        width: 150,
      },
      {
        title: <FormattedMessage id='dashboard.action' />,
        dataIndex: 'id',
        align: 'center',
        render: (id) => (
          <Link to={`${BASE_URL}/request-detail/${id}`}>
            <Button className={styles.view_detail} style={{ color: '#fff', marginLeft: 8 }} type='link' size='small'>
              <FormattedMessage id='dashboard.view_detail' />
            </Button>
          </Link>
        ),
        width: 50,
      },
    ];
    if (data.length === 0) {
      return (
        <div className={styles.dashboard}>
          <Helmet title='Dashboard' />

          <div className={styles.block}>
            <div className={styles.tab_section}>
              <NavigationTabSwitch />
              <div className={styles.inner_empty}>
                <Image className={styles.cart_image} src='/images/dashboard-image.svg' alt='Bholdus dashboard' />
                <div className={styles.message}>
                  <FormattedMessage id='dashboard.to_buy_bho' />, &nbsp;
                  <a
                    href='https://pancakeswap.finance/swap?outputCurrency=0x8717e80eff08f53a45b4a925009957e14860a8a8'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: 'white' }}
                  >
                    <FormattedMessage id='dashboard.imo_click_here' />
                  </a>
                </div>
                {/* <InfoMessage /> */}
                {/* <div className={styles.transaction_empty_warning_wrapper}>
                <p className={styles.warning_label}>
                  <FormattedMessage id='create_request.warning' />:
                </p>
                <div className={styles.warning_content}>
                  <div>
                    - <FormattedMessage id='dashboard.empty_transaction_warning_1' />{' '}
                    <a href={`${SITE_ADDRESS}${BASE_URL}/dashboard`}>{`${SITE_ADDRESS}${BASE_URL}/dashboard`}</a>
                  </div>
                  <div>
                    - <FormattedMessage id='dashboard.empty_transaction_warning_2' />
                  </div>
                  <div>
                    - <FormattedMessage id='dashboard.empty_transaction_warning_3' />{' '}
                    <a href={`${SITE_ADDRESS}${BASE_URL}/`}>{`${SITE_ADDRESS}${BASE_URL}/`}</a>
                  </div>
                  <div>
                    - <FormattedMessage id='dashboard.empty_transaction_warning_4' />
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          {/* <BuyButton /> */}
        </div>
      );
    }
    return (
      <div className={styles.dashboard}>
        <Helmet title='Dashboard' />

        <div className={styles.block}>
          <div className={styles.tab_section}>
            <NavigationTabSwitch />
            <div className={styles.inner}>
              <div className={styles.total}>
                <div className={styles.total_usd}>
                  <Image src='/images/usd.png' alt='total usd' width='50' height='50' />
                  <div className={styles.number_usd}>
                    <NumberFormat thousandSeparator=',' value={balance.usd} displayType='text' decimalScale={3} />
                  </div>
                </div>
                <div className={styles.total_bho}>
                  <div className={styles.number_bho}>
                    <NumberFormat thousandSeparator=',' value={balance.bho} displayType='text' decimalScale={3} />
                  </div>

                  <Image src='/images/bho.png' alt='total bho' width='50' height='50' />
                </div>
              </div>
              <Table
                loading={fetchStatus.isFetching}
                className='table__scrollTable dashboard_table'
                scroll={{ x: 'max-content' }}
                columns={tableColumns}
                dataSource={addKeyToObjects(fetchStatus.total, data, 'id', true, true)}
                onChange={this.handleChange}
                rowKey={(record) => record.id}
                bordered
                rowClassName={(record, index) => (index % 2 === 0 ? styles.table_row_dark : styles.table_row_light)}
                pagination={{
                  hideOnSinglePage: true,
                  size: 'small',
                  pageSize,
                  defaultCurrent: currentPage,
                  onChange: this.onPageChange,
                  total: fetchStatus.total,
                  position: ['bottomCenter'],
                  className: styles.dashboard_pagination,
                }}
              />
              <div className={styles.delay_message}>
                <Image src='/images/hour-glass.png' alt='delay' />
                &nbsp;
                <FormattedMessage id='dashboard.delay_message' values={{ br: <br /> }} />
              </div>
            </div>
          </div>
          {/* <BuyButton key='dashboard_buy_button' /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: getBalance(state),
  data: getTransactionList(state),
  fetchStatus: getFetchStatus(state),
  allPaymentMethodList: getAllPaymentMethodList(state),
  user: state.user,
  savedAtPaymentMethod: getSavedAtPaymentMethod(state),
});

export default withRouter(connect(mapStateToProps)(Dashboard));
