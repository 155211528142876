/**
 *
 * StakePage
 *
 */
import React, { useEffect } from 'react';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import StakeItem from 'components/StakeItem';
import NavigationTabSwitch from 'components/NavigationTabSwitch';
import lodash from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getStakeDetail, getFetchStatus as getStakeFetchStatus } from 'redux/stake/reducers';
import { FETCH_STAKE_DETAIL } from 'redux/stake/actions';
import { BSC_SCAN_URL } from 'configs';
import styles from './style.module.scss';

const statusColor = {
  pending: 'white',
  released: '#1FC7D4',
};

const StakePage = () => {
  const { id } = useParams();
  const stake = useSelector((state) => getStakeDetail(state, id));
  const stakeFetchStatus = useSelector(getStakeFetchStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: FETCH_STAKE_DETAIL.REQUEST,
      payload: {
        id,
      },
    });
  }, [dispatch, id]);

  const tableColumns = [
    {
      title: <FormattedMessage id='request_detail.timeline' />,
      dataIndex: 'release_date',
      align: 'left',
      render: (release_date) => `${dayjs(release_date).format('DD/MM/YYYY')}`,
      width: 30,
    },
    {
      title: <FormattedMessage id='request_detail.information' />,
      dataIndex: ['amount', 'release_transaction_hash'],
      align: 'left',
      render: (text, records) => (
        <>
          <div>
            <FormattedMessage id='staking_detail.amount' />:{' '}
            <FormattedNumber maximumFractionDigits={18} value={records.amount} /> BHO
          </div>
          <div style={styles.table_content}>
            <FormattedMessage id='staking_detail.transaction_id' />:{' '}
            {lodash.get(records, 'release_transaction_hash', '') ? (
              <Link
                to={{
                  pathname: `${BSC_SCAN_URL}/tx/${records.release_transaction_hash}`,
                }}
                target='_blank'
                style={{ color: '#1FC7D4' }}
              >
                {records.release_transaction_hash.substring(0, 25)}
                ...
              </Link>
            ) : null}
          </div>
        </>
      ),
      width: 50,
    },
    {
      title: <FormattedMessage id='request_detail.lock_status' />,
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <span style={{ color: statusColor[status], fontWeight: 'bold', textTransform: 'capitalize' }}>
          <FormattedMessage id={`staking_detail.${status}`} />
        </span>
      ),
      width: 20,
    },
  ];

  //  useEffect(() => {
  //    dispatch({
  //      type: FETCH_STAKE_DETAIL.REQUEST,
  //      payload: {
  //        offset: 0,
  //        limit: 20,
  //      },
  //    });
  //  }, [dispatch]);

  return (
    <div className={styles.staking_detail}>
      <div className={styles.block}>
        <div className={styles.tab_section}>
          <NavigationTabSwitch />
          <div className={styles.inner}>
            <div className={styles.back_btn_wrapper}>
              <Link className={styles.back_btn} to='/staking/staking-list'>
                <img src='/images/left-arrow.svg' alt='back' />
                <FormattedMessage id='staking_detail.back' />
              </Link>
            </div>

            <div className={styles.align_center}>
              <StakeItem noHover stake={stake} />
              <div className={styles.contract}>
                <span className={styles.contract_label}>
                  <FormattedMessage id='request_detail.contract' />
                </span>
                <Input
                  className={styles.contract_input}
                  disabled
                  value={lodash.get(stake, 'staking_contract_address')}
                />
                <Link
                  className={styles.look_up}
                  to={{
                    pathname: `${BSC_SCAN_URL}/address/${lodash.get(stake, 'staking_contract_address')}`,
                  }}
                  target='_blank'
                >
                  <SearchOutlined style={{ fontSize: '16px', color: '#fff' }} />
                </Link>
              </div>
              <div className={styles.table_section}>
                <Table
                  loading={stakeFetchStatus.isFetching}
                  className='table__scrollTable'
                  scroll={{ x: 'max-content' }}
                  dataSource={lodash.get(stake, 'staking_rewards', []).sort((a, b) => a.index - b.index)}
                  columns={tableColumns}
                  rowKey={(record) => record.id}
                  bordered
                  rowClassName={(record, index) => (index % 2 === 0 ? styles.table_row_dark : styles.table_row_light)}
                  pagination={{
                    hideOnSinglePage: true,
                    size: 'small',
                    pageSize: 6,
                    position: ['bottomCenter'],
                    className: styles.table_pagination,
                  }}
                  tableLayout='fixed'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakePage;
