import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Button, Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import actions from 'redux/user/actions';
import { changePassword } from 'services/user';
import { showError } from 'utils/toastMessage';
import { analysePassword } from 'utils/analysePassword';
// import { SET_ERROR } from 'redux/error/actions';
import analytics from 'utils/analytics';
import styles from './style.module.scss';

class ChangePass extends Component {
  state = {
    loading: false,
  };

  componentDidMount = () => {
    analytics.sendEvent({
      action: 'change_password_screen',
      label: 'visit_screen',
    });
  };

  onFinish = async (values) => {
    const { dispatch } = this.props;
    this.setState({
      loading: true,
    });
    try {
      const res = await changePassword(values.password, values.new_password);
      if (!res.data.error) {
        dispatch({
          type: actions.LOGOUT,
        });
      } else {
        showError(get(res, 'data.message.messages[0].id'));
      }
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      console.log(err);
      // dispatch({
      //   type: SET_ERROR,
      //   payload: {
      //     error: 'Network error',
      //   },
      // });
    }
  };

  render() {
    const { intl } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Helmet title='Change password' />

        <div className={styles.block}>
          <div className={styles.inner}>
            <div className={styles.title}>
              <FormattedMessage id='change_password.title' />
            </div>
            <div className={styles.form}>
              <Form layout='vertical' hideRequiredMark onFinish={this.onFinish}>
                <Form.Item
                  label={<FormattedMessage id='change_password.current_password' />}
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id='change_password.current_password_required' />,
                    },
                  ]}
                >
                  <Input.Password
                    size='large'
                    className={styles.mail_input}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined style={{ color: '#fff' }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ color: '#fff' }} />
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id='change_password.new_password' />}
                  name='new_password'
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id='change_password.new_password_required' />,
                    },
                    () => ({
                      validator(_, value) {
                        if (value && !analysePassword(value)) {
                          return Promise.reject(
                            new Error(
                              intl.formatMessage({
                                id: 'change_password.strong_password_required',
                              })
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size='large'
                    className={styles.mail_input}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined style={{ color: '#fff' }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ color: '#fff' }} />
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id='change_password.confirm_new_password' />}
                  name='confirm_new_password'
                  dependencies={['new_password']}
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id='change_password.confirm_new_password_required' />,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'change_password.match_password_required',
                            })
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size='large'
                    className={styles.mail_input}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined style={{ color: '#fff' }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ color: '#fff' }} />
                      )
                    }
                  />
                </Form.Item>

                <Form.Item>
                  <Button className={styles.send_mail_button} htmlType='submit' loading={loading}>
                    <FormattedMessage id='change_password.change_password' />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default injectIntl(connect(mapStateToProps)(ChangePass));
