import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import reducers from 'redux/reducers';
import sagas from 'redux/sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: '@bholdus-sale',
  storage,
  whitelist: [
    'user',
    'saleConfig',
    'referral',
    // NOTE: underneath reducers are saved for avoid call api when many user making create-request
    'paymentMethod',
    'saleConfig',
  ],
};

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
const persisted = persistReducer(persistConfig, reducers());
const store = createStore(persisted, compose(applyMiddleware(...middlewares)));
// const store = createStore(reducers(), compose(applyMiddleware(...middlewares)));

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
