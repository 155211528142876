import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getStakeProgramList } from 'services/stake';
import lodash from 'lodash';
import { showError } from 'utils/toastMessage';
// import { SET_ERROR } from 'redux/error/actions';
import { FETCH_STAKE_PROGRAM_LIST } from './actions';

export function* REQUEST_STAKE_PROGRAM_LIST({ payload }) {
  yield put({
    type: FETCH_STAKE_PROGRAM_LIST.PENDING,
  });
  try {
    const { offset, limit, locale } = payload;
    const response = yield call(getStakeProgramList, { offset, limit, locale });
    if (response.data.error) {
      showError(lodash.get(response, 'data.message.messages[0].id'));
      yield put({
        type: FETCH_STAKE_PROGRAM_LIST.ERROR,
      });
    } else {
      const responseData = response.data;
      yield put({
        type: FETCH_STAKE_PROGRAM_LIST.SUCCESS,
        payload: {
          data: responseData.data || [],
          total: responseData.total,
          currentOffset: offset + (limit || 20),
        },
      });
      for (let i = 0; i < responseData.data.length; i += 1) {
        const currency = lodash.get(responseData.data[i], 'detail.currency');
        if (currency) {
          yield put({
            type: FETCH_STAKE_PROGRAM_LIST.REQUEST,
            payload: {
              currency,
            },
          });
        }
      }
    }
  } catch (err) {
    // yield put({
    //   type: SET_ERROR,
    //   payload: {
    //     error: 'Network error',
    //   },
    // });
    yield put({
      type: FETCH_STAKE_PROGRAM_LIST.ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FETCH_STAKE_PROGRAM_LIST.REQUEST, REQUEST_STAKE_PROGRAM_LIST)]);
}
