import { combineReducers } from 'redux';
import { updateById, appendByIdsUpdateOld } from 'utils/convertData';
import actions from 'redux/user/actions';
import { FETCH_STAKE_LIST, FETCH_STAKE_DETAIL } from './actions';

const initialState = {
  isFetching: false,
  currentOffset: 0,
  total: 0,
};

export const fetchStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAKE_LIST.PENDING:
      return { ...state, isFetching: true };
    case FETCH_STAKE_LIST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentOffset: action.payload.currentOffset,
        total: action.payload.total,
      };
    case FETCH_STAKE_LIST.ERROR:
      return { ...state, isFetching: false };

    case FETCH_STAKE_DETAIL.PENDING:
      return { ...state, isFetching: true };
    case FETCH_STAKE_DETAIL.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_STAKE_DETAIL.ERROR:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STAKE_LIST.SUCCESS:
      return appendByIdsUpdateOld(state, action.payload.data, 'id');
    case FETCH_STAKE_DETAIL.SUCCESS:
      return updateById(state, action.payload.data, 'id');
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_STAKE_LIST.SUCCESS: {
      if (action.payload.currentOffset === 20) {
        return action.payload.data.map((item) => item.id);
      }
      return [...state, ...action.payload.data.map((item) => item.id)].filter(
        (value, index, self) => self.indexOf(value) === index
      );
    }
    case FETCH_STAKE_DETAIL.SUCCESS:
      return [...state, action.payload.data.id].filter((value, index, self) => self.indexOf(value) === index);

    default:
      return state;
  }
};

export default combineReducers({
  fetchStatus,
  byId,
  visibleIds,
});

export const getStakeList = (state) => state.stake.visibleIds.map((id) => state.stake.byId[id]);
export const getStakeDetail = (state, id) => state.stake.byId[id];

export const getFetchStatus = (state) => state.stake.fetchStatus;
