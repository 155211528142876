import { requestAPI } from './index';
import { BASE_API_URL } from '../configs';

export async function getBalance() {
  const url = `${BASE_API_URL}/transactions/total`;

  return requestAPI({
    url,
    method: 'get',
  })
    .then((res) => res)
    .catch((err) => err.response);
}
